import { createSlice } from '@reduxjs/toolkit'

import { fetchOnCallUsersForZone } from './actions/fetchOnCallUsersForZone'
import { OnCallUserState } from './onCallUser.types'

const initialState: OnCallUserState = {
  data: {},
  error: undefined,
  isLoading: false,
}

export const onCallUsersSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchOnCallUsersForZone.pending, state => {
        state.isLoading = true
        state.error = undefined
      })
      .addCase(fetchOnCallUsersForZone.fulfilled, (state, action) => {
        state.data = action.payload.normalized.oncallUserApi || {}
        state.isLoading = false
        state.error = undefined
      })
      .addCase(fetchOnCallUsersForZone.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message as unknown as Error
      })
  },
  initialState,
  name: 'onCallUsers',
  reducers: {},
})

export default onCallUsersSlice.reducer
