import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { Housekeeper } from '../housekeepers.types'
import { getHousekeepers } from './getHousekeepers'

const getHousekeepersState = (state: ApplicationState) => state.housekeepers
const getManagerId = (_: ApplicationState, managerId: string) => managerId

/**
 * Returns all housekeepers that have the specified manager ID
 */
export const getHousekeepersByManagerId = createSelector(
  // Input selectors
  [getHousekeepersState, getManagerId],

  // Output selector
  (housekeepersState, managerId: string): Housekeeper[] => {
    const housekeepers = getHousekeepers(housekeepersState)
    return housekeepers.filter(hk => hk.manager?.id === managerId)
  },
) 