import without from 'lodash/fp/without'
import React from 'react'

import {
  format,
  DateFormat,
  createDateString,
} from 'packages/utils/dateHelpers'
import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { coverageService } from 'app/hkhub/store/coverage/coverage.service'
import {
  isWorkingCoverageTypes,
  CoverageApiFields,
} from 'app/hkhub/store/coverage/coverage.types'
import { getCoverageByDate } from 'app/hkhub/store/coverage/selectors'
import { useAppSelector } from 'app/hkhub/store/hooks'
import { ApplicationState } from 'app/hkhub/store/store'
import { UserApiFieldsWithoutPII } from 'app/hkhub/store/users'

type UserAvailability = {
  hasError: boolean
  isAvailable: boolean
  isLoading: boolean
}

export const getParams = (date: Date, userId: string): RequestOptions => ({
  fields: {
    coverage: without(['details'])(CoverageApiFields),
    requestedUser: UserApiFieldsWithoutPII,
    user: UserApiFieldsWithoutPII,
  },
  filter: {
    date_range: [
      format(date, DateFormat.ApiUtcShort),
      format(date, DateFormat.ApiUtcShort),
    ],
    user: userId,
  },
  include: ['cross_coverage', 'user', 'requested_user', 'scheduling_user'],
  page: { size: 500 },
})

export const useUserStandardAvailability = (
  date: Date,
  userId?: string,
): UserAvailability => {
  const [isAvailable, setIsAvailable] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)

  const coverages = useAppSelector((state: ApplicationState) =>
    getCoverageByDate(state, createDateString(date)),
  )

  React.useEffect(() => {
    setHasError(false)

    if (!userId) return

    setIsLoading(true)
    const userCoverageFromRedux = coverages.find(
      coverage => coverage.schedulingUserId === userId,
    )
    if (userCoverageFromRedux) {
      setIsAvailable(
        isWorkingCoverageTypes.includes(userCoverageFromRedux.coverageType),
      )

      setIsLoading(false)
    } else {
      setIsLoading(true)
      coverageService
        .fetchCoverage(getParams(date, userId))
        .then(response => {
          const coverage = response.normalized.coverage
          const userCoverage =
            coverage &&
            Object.values(coverage).find(
              item => item.relationships.schedulingUser.data.id === userId,
            )
          setIsAvailable(
            userCoverage
              ? isWorkingCoverageTypes.includes(
                  userCoverage.attributes.coverageType,
                )
              : false,
          )
        })
        .catch(() => {
          setHasError(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [userId, coverages, date])

  return {
    hasError,
    isAvailable,
    isLoading,
  }
}
