/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum UiActionTypes {
  SET_CLEAN_DRAWER_STATE = 'UI/SET_CLEAN_DRAWER_STATE',
  SET_COVERAGE_PARTNER_DRAWER_STATE = 'UI/SET_COVERAGE_PARTNER_DRAWER_STATE',
  SET_LOADING_CLEAN_ID = 'UI/SET_LOADING_CLEAN_ID',
  SET_TICKET_DRAWER_STATE = 'UI/SET_TICKET_DRAWER_STATE',
  SET_UNIT_DRAWER_STATE = 'UI/SET_UNIT_DRAWER_STATE',
  SET_VISIT_DRAWER_STATE = 'UI/SET_VISIT_DRAWER_STATE',
}

export type UnitDrawerState = {
  isOpen: boolean
  unitId: string
}

export type CleanDrawerState = {
  cleanId: string
  isOpen: boolean
}

export type TicketDrawerState = {
  isOpen: boolean
  ticketId: string
}

export type VisitDrawerState = {
  isOpen: boolean
  visitId: string
}

export type CoveragePartnerDrawerState = {
  isOpen: boolean
  userId: string
  userName?: string
}

export type UiState = {
  drawers: {
    cleanDrawer: CleanDrawerState
    coveragePartnerDrawer: CoveragePartnerDrawerState
    ticketDrawer: TicketDrawerState
    unitDrawer: UnitDrawerState
    visitDrawer: VisitDrawerState
  }
  loadingCleanId: string
}
