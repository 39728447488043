import { difference, snakeCase } from 'lodash/fp'

import {
  AllVisitAttributeNames,
  AllVisitAttributes,
  JSONApiAssignmentsMap,
  JSONApiHousekeepersMap,
  JSONApiUsersMap,
} from 'packages/grimoire'
import {
  JSONApiObjectWithRelationshipsMap,
  ToManyRelationship,
  ToOneRelationship,
  JSONApiObjectWithRelationships,
} from 'packages/utils/store/jsonapi.types'

import { JSONApiLockboxesMap } from '../lockboxes'
import { JSONAPIUnitSearchMap } from '../units'

export * from 'packages/grimoire/src/visit/visit.types'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum VisitsActionTypes {
  CREATE_VISIT = 'VISITS/API/CREATE_VISIT',
  CREATE_VISIT_FAILURE = 'VISITS/CREATE_VISIT_FAILURE',
  CREATE_VISIT_SUCCESS = 'VISITS/CREATE_VISIT_SUCCESS',

  FETCH_VISIT = 'VISITS/API/FETCH_VISIT',
  FETCH_VISITS_BY_ZONE_AND_DATE = 'VISITS/API/FETCH_VISITS_BY_ZONE_AND_DATE',
  FETCH_VISITS_BY_ZONE_AND_DATE_FAILURE = 'VISITS/FETCH_VISITS_BY_ZONE_AND_DATE_FAILURE',

  FETCH_VISITS_BY_ZONE_AND_DATE_SUCCESS = 'VISITS/FETCH_VISITS_BY_ZONE_AND_DATE_SUCCESS',
  FETCH_VISIT_FAILURE = 'VISITS/FETCH_VISIT_FAILURE',
  FETCH_VISIT_SUCCESS = 'VISITS/FETCH_VISIT_SUCCESS',

  UPDATE_VISIT = 'VISITS/API/UPDATE_VISIT',
  UPDATE_VISIT_FAILURE = 'VISITS/UPDATE_VISIT_FAILURE',
  UPDATE_VISIT_SUCCESS = 'VISITS/UPDATE_VISIT_SUCCESS',
}

export type VisitsState = {
  data: JSONApiVisitMap
}

/**********************************************************
 * Visit
 *********************************************************/
export type VisitRelationships = {
  activeAssignments: ToManyRelationship
  assignedHousekeepers: ToManyRelationship
  inspectionFlags: ToManyRelationship
  smartLocks: ToManyRelationship
  taskPhotos: ToManyRelationship
  tickets: ToManyRelationship
  unit: ToOneRelationship
}

export type VisitsResponse = {
  assignment: JSONApiAssignmentsMap
  housekeeper: JSONApiHousekeepersMap
  lockBox: JSONApiLockboxesMap
  unit: JSONAPIUnitSearchMap
  user: JSONApiUsersMap
}

export type Visit = {
  assignedHkIds: string[]
  assignmentIds: string[]
  id: string
  inspectionFlagIds: string[]
  nextReservationId?: string
  taskPhotoIds: string[]
  ticketIds: string[]
  unitId: string
} & VisitAttributes

export type VisitAttributes = Omit<
  AllVisitAttributes,
  'createdAt' | 'predictedCleanTime' | 'timersSubmitted'
>

export const VisitAttributeNames = difference(AllVisitAttributeNames)([
  'createdAt',
  'metadata',
])

export const VisitApiFields = VisitAttributeNames.map<string>(snakeCase)

export type RawVisit = JSONApiObjectWithRelationships<
  VisitAttributes,
  VisitRelationships
>

export type JSONApiVisitMap = JSONApiObjectWithRelationshipsMap<
  VisitAttributes,
  VisitRelationships
>

/**********************************************************
 * Utility Types
 *********************************************************/
export type HkDateVisitBuckets = {
  [hkId: string]: DateVisitBuckets
}

export type UnitDateVisitBuckets = {
  [unitId: string]: DateVisitBuckets
}

export type DateVisitBuckets = {
  [date: string]: Visit[]
}

/**********************************************************
 * API Types
 *********************************************************/

export type VisitPatchAttributes = {
  hks: { id: string }[]
}

export type VisitPatchData = {
  id: string
} & Partial<VisitPatchAttributes>

export type CreateVisitPostData = {
  date?: Date
  housekeeperId?: string
  unitId: string
}
