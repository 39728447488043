import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useToast } from 'packages/common'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { getSortedHks } from 'app/hkhub/store/housekeepers/selectors'
import { AppDispatch, ApplicationState } from 'app/hkhub/store/store'
import { Visit } from 'app/hkhub/store/visits'
import { updateVisit } from 'app/hkhub/store/visits/actions/updateVisit'

import { VisitAssignmentEditor } from './VisitAssignmentEditor'

type VisitAssignmentEditorContainerProps = {
  onCancelEditClick: () => void
  visit: Visit
}

export const VisitAssignmentEditorContainer: React.FC<VisitAssignmentEditorContainerProps> =
  React.memo(({ visit, onCancelEditClick }) => {
    const dispatch: AppDispatch = useDispatch()
    const { showToast } = useToast()

    const { t } = useI18n()

    const housekeepers = useSelector((state: ApplicationState) =>
      getSortedHks(state.housekeepers),
    )

    // visit can be unassigned, in that case selectedHkId is undefined
    const [selectedHkId, setSelectedHkId] = React.useState<string | undefined>(
      visit.assignedHkIds[0],
    )

    const onSuccess = React.useCallback(() => {
      showToast({ message: t(Slugs.visitUpdated) })
      // Close the drawer after a successful update
      onCancelEditClick()
    }, [onCancelEditClick, showToast, t])

    const [updateState, updateVisitFn, resetUpdateState] = useAsyncFnWithReset(
      async ({ hkId }) =>
        dispatch(
          updateVisit(
            {
              hks: [{ id: hkId }],
              id: visit.id,
            },
            { onSuccess },
          ),
        ),
      [dispatch, onSuccess, visit.id],
    )

    const disableSubmit =
      !selectedHkId ||
      selectedHkId === visit.assignedHkIds[0] ||
      updateState.loading

    const getSelectedHk = React.useCallback(
      (hkId: string | undefined) => {
        if (!hkId) return
        return housekeepers.find(otherHk => otherHk.id === hkId)
      },
      [housekeepers],
    )

    const handleUpdateVisit = React.useCallback(() => {
      updateVisitFn({ hkId: selectedHkId })
    }, [updateVisitFn, selectedHkId])

    return (
      <VisitAssignmentEditor
        disableSubmit={disableSubmit}
        selectedHk={getSelectedHk(selectedHkId)}
        housekeepers={housekeepers}
        onCancelEditClick={onCancelEditClick}
        resetUpdateState={resetUpdateState}
        setSelectedHkId={setSelectedHkId}
        updateState={updateState}
        updateVisitFn={handleUpdateVisit}
      />
    )
  })
