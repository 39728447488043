import { createAsyncThunk } from '@reduxjs/toolkit'

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { onCallUsersService } from '../onCallUser.service'

export const getOnCallUserParams = (zoneId: string): RequestOptions => ({
  filter: {
    zone: zoneId,
  },
})

export const fetchOnCallUsersForZone = createAsyncThunk(
  'onCallUser/api/fetchOnCallUsersForZone',
  async (zoneId: string) => {
    const onCallUserParams = getOnCallUserParams(zoneId)
    const onCallUserResult = await onCallUsersService.fetchOnCallUsers(
      onCallUserParams,
    )
    return onCallUserResult
  },
)
