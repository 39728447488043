import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import { IconName, SvgIcon } from 'packages/iconic'

import { CoveragePartnerDrawer } from 'app/hkhub/components/schedule/components/common/CoveragePartnerDrawer'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import { fetchDataForStaff } from 'app/hkhub/store/highlights/actions/fetchDataForStaff'
import { getHousekeepersByManagerId } from 'app/hkhub/store/housekeepers/selectors'
import { AppDispatch, ApplicationState } from 'app/hkhub/store/store'
import { setCoveragePartnerDrawerStateAction } from 'app/hkhub/store/ui/actions'
import { getAuthUser } from 'app/hkhub/store/users/selectors'
import { getCoveragePartner } from 'app/hkhub/store/users/selectors/getCoveragePartner'

import * as St from './StaffPage.styles'

const EMPTY_ARRAY: never[] = []

export enum StaffPageTestIds {
  container = 'HKhub__StaffPage__container',
  staffTable = 'HKhub__StaffPage__staffTable',
  staffTableBody = 'HKhub__StaffPage__staffTableBody',
}

const CoveragePartnerCell: React.FC<{ userId: string; userName: string }> = ({ userId, userName }) => {
  const dispatch = useDispatch()
  const coveragePartner = useSelector((state: ApplicationState) =>
    getCoveragePartner(state, userId),
  )
  const coveragePartnerName = useMemo(() => {
    return coveragePartner
      ? `${coveragePartner.attributes.firstName} ${coveragePartner.attributes.lastName}`
      : '-'
  }, [coveragePartner])

  const handleEditClick = useCallback(() => {
    dispatch(
      setCoveragePartnerDrawerStateAction({
        isOpen: true,
        userId,
        userName,
      }),
    )
  }, [dispatch, userId, userName])

  return (
    <St.CellContent>
      {coveragePartnerName}
      <St.EditIcon onClick={handleEditClick}>
        <SvgIcon icon={IconName.edit} size={20} />
      </St.EditIcon>
    </St.CellContent>
  )
}

export const StaffPage: React.FC = React.memo(() => {
  const { t } = useI18n()
  const dispatch = useDispatch<AppDispatch>()
  const authUser = useSelector(getAuthUser)
  const [isLoading, setIsLoading] = useState(true)

  const housekeepers = useSelector((state: ApplicationState) =>
    authUser?.id ? getHousekeepersByManagerId(state, authUser.id) : EMPTY_ARRAY,
  )

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)

      try {
        if (authUser?.id) {
          await dispatch(fetchDataForStaff({ userId: authUser.id }))
        }
      } finally {
        setIsLoading(false)
      }
    }

    loadData()
  }, [dispatch, authUser?.id])

  if (isLoading) {
    return <St.Content>Loading...</St.Content>
  }

  return (
    <St.Content data-testid={StaffPageTestIds.container}>
      <CoveragePartnerDrawer />
      <St.Table data-testid={StaffPageTestIds.staffTable}>
        <thead>
          <tr>
            <St.HeaderCell>{t(Slugs.headerName)}</St.HeaderCell>
            <St.HeaderCell>{t(Slugs.headerUnits)}</St.HeaderCell>
            <St.HeaderCell>{t(Slugs.headerTickets)}</St.HeaderCell>
            <St.HeaderCell>{t(Slugs.coveragePartnerTitle)}</St.HeaderCell>
            <St.HeaderCell>{t(Slugs.headerRoles)}</St.HeaderCell>
          </tr>
        </thead>
        <tbody data-testid={StaffPageTestIds.staffTableBody}>
          {housekeepers.map((housekeeper) => (
            <St.BodyRow key={housekeeper.id}>
              <St.BodyCell>{`${housekeeper.user.firstName} ${housekeeper.user.lastName}`}</St.BodyCell>
              <St.BodyCell>-</St.BodyCell>
              <St.BodyCell>-</St.BodyCell>
              <St.BodyCell>
                <CoveragePartnerCell 
                  userId={housekeeper.user.id} 
                  userName={`${housekeeper.user.firstName} ${housekeeper.user.lastName}`}
                />
              </St.BodyCell>
              <St.BodyCell>
                <St.CellContent>
                  -<St.EditIcon>
                    <SvgIcon icon={IconName.edit} size={20} />
                  </St.EditIcon>
                </St.CellContent>
              </St.BodyCell>
            </St.BodyRow>
          ))}
        </tbody>
      </St.Table>
    </St.Content>
  )
})
