import * as React from 'react'
import { Options } from 'react-select'
import AsyncSelect from 'react-select/async'

import { User } from 'packages/grimoire'

import { Slugs, useI18n } from 'app/hkhub/i18n'

type CoveragePartnerSelectorProps = {
  disabled?: boolean
  label?: string
  loadOptions: (inputValue: string, callback: (options: Options<User>) => void) => void
  onUserSelectionChange: (value: User) => void
  selectedUser?: User
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    noResults: t(Slugs.coveragePartnerSearchNoResults),
    placeholder: t(Slugs.coveragePartnerPlaceholder),
    typeToSearch: t(Slugs.coveragePartnerSearchTypeToSearch),
  }
}

const CoveragePartnerSelector: React.FC<CoveragePartnerSelectorProps> = ({
  onUserSelectionChange,
  selectedUser,
  disabled,
  label,
  loadOptions,
}) => {
  const strings = useTranslations()

  const handleChange = React.useCallback((selectedOption: User | null) => {
    if (selectedOption) {
      onUserSelectionChange(selectedOption)
    }
  }, [onUserSelectionChange])

  return (
    <AsyncSelect<User>
      aria-label={label}
      classNamePrefix="coveragePartnerSelector"
      defaultOptions={selectedUser ? [selectedUser] : []}
      loadOptions={loadOptions}
      onChange={handleChange}
      getOptionLabel={(user: User) => `${user.firstName} ${user.lastName}`}
      getOptionValue={(user: User) => user.id}
      value={selectedUser}
      isDisabled={!!disabled}
      placeholder={strings.placeholder}
      noOptionsMessage={({ inputValue }) =>
        inputValue === '' ? strings.typeToSearch : strings.noResults
      }
    />
  )
}

export default CoveragePartnerSelector 