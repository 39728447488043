import React from 'react'

import { ModalConfig, useModalContext } from 'packages/common'

import { CreateVisitModalForm } from '../CreateVisitModalForm/CreateVisitModalForm'

export type UseCreateVisitModalProps = Pick<ModalConfig, 'modalProps'>

type UseCreateVisitModal = {
  showModal: () => void
}

export const useCreateVisitModal = ({
  modalProps,
}: UseCreateVisitModalProps): UseCreateVisitModal => {
  const { showModal } = useModalContext()

  const showCompleteModal = React.useCallback(
    () =>
      showModal({
        childRenderer: ({ beginClose }) => (
          <CreateVisitModalForm onClose={beginClose} />
        ),
        modalProps,
      }),
    [showModal, modalProps],
  )

  return {
    showModal: showCompleteModal,
  }
}
