import styled from '@emotion/styled'
import { useDecision } from '@optimizely/react-sdk'
import React from 'react'

import { Button } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { Features } from 'packages/optimizely'
import { useBreakpoint } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'

import { useCreateVisitModal } from './hooks/useCreateVisitModal'

export enum CreateVisitModalTestIds {
  addVisitButton = 'CreateVisitModal__addVisitButton',
  addVisitIcon = 'CreateVisitModal__addVisitIcon',
}

const St = {
  AddVisitButton: styled(Button)`
    margin-left: 8px;
  `,
  AddVisitIcon: styled(SvgIcon)`
    margin-left: 16px;
    padding-left: 6px;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    addVisit: ut(Slugs.addVisit),
  }
}

export const CreateVisitModal: React.FC = React.memo(() => {
  const [decision] = useDecision(Features.CREATING_VISITS_FROM_HK_HUB)
  const createVisitEnabled = decision.enabled

  const strings = useTranslations()
  const breakpoint = useBreakpoint()
  const isSmallScreen = breakpoint === 'SM'

  const { showModal } = useCreateVisitModal({})

  if (!createVisitEnabled) {
    return null
  }

  return (
    <>
      {isSmallScreen ? (
        <St.AddVisitIcon
          size={22}
          icon={IconName.plus}
          onClick={showModal}
          dataTestId={CreateVisitModalTestIds.addVisitIcon}
        />
      ) : (
        <St.AddVisitButton
          buttonType="utilityInverted"
          onClick={showModal}
          dataTestId={CreateVisitModalTestIds.addVisitButton}
        >
          {strings.addVisit}
        </St.AddVisitButton>
      )}
    </>
  )
})
