import { createSelector } from 'reselect'

import { ApplicationState } from 'app/hkhub/store/store'

import { RawUser } from '../users.types'

const getUsersData = (state: ApplicationState) => state.users.data

export const getCoveragePartner = createSelector(
  [getUsersData, (_state: ApplicationState, userId: string) => userId],
  (usersData, userId): RawUser | undefined => {
    const user = usersData[userId] as RawUser

    if (!user?.relationships?.currentCoveragePartner?.data?.id) {
        return undefined
    }
    
    const coveragePartnerId = user.relationships.currentCoveragePartner.data.id
    return usersData[coveragePartnerId]
  },
) 