import styled from '@emotion/styled'
import React from 'react'

import { Alert, Loader } from 'packages/common'
import { format } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { fullName } from 'app/hkhub/store/users'
import { formatReservationDateTimeAttributes } from 'app/hkhub/utils'

import { UnitReservation } from '../hooks/useUnitReservations'
import { useUnitVisits } from '../hooks/useUnitVisits'

const St = {
  LoaderContainer: styled.div`
    position: relative;
    height: 56px;
    margin-bottom: 1rem;
  `,
}

type TranslationsProps = Pick<
  CreateVisitWarningsProps,
  'otherVisitUser' | 'selectedDate'
>

const useTranslations = (props: TranslationsProps) => {
  const { t } = useI18n()

  const userName = props.otherVisitUser ? fullName(props.otherVisitUser) : ''
  const selectedDateStr = format(props.selectedDate, 'LLLL d')

  const getUnitIsOccupiedTranslation = (
    checkInDate: string,
    checkInTime: string,
    checkOutDate: string,
    checkOutTime: string,
  ) => {
    return t(Slugs.createVisitErrorUnitHasReservation, {
      checkInDate,
      checkInTime,
      checkOutDate,
      checkOutTime,
    })
  }

  return {
    housekeeperNotAvailable: t(Slugs.housekeeperNotAvailable),
    loadingHousekeeperAvailability: t(Slugs.loading, {
      defaultValue: 'Loading availability...',
    }),
    otherUserHasVisit: t(Slugs.createVisitErrorOtherUserVisitThisDay, {
      date: selectedDateStr,
      userName,
    }),
    unitHasUnassignedVisit: t(
      Slugs.createVisitErrorUnitHasUnassignedVisitThisDay,
      { date: selectedDateStr },
    ),
    unitIsOccupied: getUnitIsOccupiedTranslation,
    userHasVisit: t(Slugs.createVisitErrorUserHasVisitThisDay, {
      date: selectedDateStr,
    }),
  }
}

export type CreateVisitWarningsProps = ReturnType<typeof useUnitVisits> & {
  assignedHousekeeper?: Housekeeper | null
  isHousekeeperAvailable: boolean
  isLoading: boolean
  reservations: UnitReservation[] | null
}

export const CreateVisitWarnings: React.FC<CreateVisitWarningsProps> =
  React.memo(
    ({
      userHasExistingVisit,
      reservations,
      otherVisitUser,
      unitHasUnassignedVisit,
      selectedDate,
      assignedHousekeeper,
      isHousekeeperAvailable,
      isLoading,
    }) => {
      const strings = useTranslations({
        otherVisitUser,
        selectedDate,
      })

      if (isLoading) {
        return (
          <St.LoaderContainer>
            <Loader />
          </St.LoaderContainer>
        )
      }

      return (
        <>
          {!!userHasExistingVisit && (
            <Alert alertType={'danger'}>{strings.userHasVisit}</Alert>
          )}
          {reservations &&
            reservations.map(res => {
              const { checkinDate, checkinTime, checkoutDate, checkoutTime } =
                formatReservationDateTimeAttributes(res)
              return (
                <Alert alertType={'warning'} key={res.id}>
                  {strings.unitIsOccupied(
                    checkinDate,
                    checkinTime,
                    checkoutDate,
                    checkoutTime,
                  )}
                </Alert>
              )
            })}
          {!!otherVisitUser && (
            <Alert alertType={'info'}>{strings.otherUserHasVisit}</Alert>
          )}
          {!!unitHasUnassignedVisit && (
            <Alert alertType={'info'}>{strings.unitHasUnassignedVisit}</Alert>
          )}
          {!!assignedHousekeeper && isHousekeeperAvailable === false && (
            <Alert alertType="warning">{strings.housekeeperNotAvailable}</Alert>
          )}
        </>
      )
    },
  )
