import { AxiosResponse } from 'axios'

import { AllTaskAttributes, JSONApiAssignmentsMap } from 'packages/grimoire'
import {
  JSONApiObject,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToManyRelationship,
  ToOneRelationship,
} from 'packages/utils/store/jsonapi.types'

import { JSONApiHousekeepersMap } from '../housekeepers'
import { JSONApiInspectionFlagsMap } from '../inspectionFlags'
import { JSONApiLockboxesMap } from '../lockboxes'
import { JSONApiReservationMap } from '../reservations'
import { JsonApiTaskPhotosMap } from '../taskPhotos'
import { JSONApiTicketsMap } from '../tickets'
import { JSONApiUnitMap } from '../units'
import { JSONApiUsersMap } from '../users'

export * from 'packages/grimoire/src/task/task.types'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum TasksActionTypes {
  FETCH_TASK = 'TASKS/API/FETCH_TASK',
  FETCH_TASK_FAILURE = 'TASKS/FETCH_TASK_FAILURE',
  FETCH_TASK_SUCCESS = 'TASKS/FETCH_TASK_SUCCESS',

  // WebSockets Events
  WS_EVENT_TASK_DEACTIVATED = 'TASKS/WS_EVENT/TASK_DEACTIVATED',
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type TasksResponse = {
  assignment: JSONApiAssignmentsMap
  assignmentChange?: JSONApiAssignmentChangeMap
  housekeeper: JSONApiHousekeepersMap
  inspectionFlag?: JSONApiInspectionFlagsMap
  lockBox: JSONApiLockboxesMap
  manager?: JSONApiUsersMap
  reservation: JSONApiReservationMap
  task: JSONApiTaskMap
  taskPhoto: JsonApiTaskPhotosMap
  ticket: JSONApiTicketsMap
  unit: JSONApiUnitMap
  user: JSONApiUsersMap
}

export type NormalizedTasksApiResponse =
  NormalizedJSONApiResponse<TasksResponse>

export type TasksServiceResponse = Promise<NormalizedTasksApiResponse>
export type TasksApiResponse = AxiosResponse<NormalizedTasksApiResponse>

export type TaskWsEvent = {
  taskId: string
  unitId: number
  zoneId: string
}

/**********************************************************
 * TASK
 *********************************************************/
export type TaskAttributes = Omit<AllTaskAttributes, 'createdAt'>

export type TaskRelationships = {
  assignedHousekeepers: ToManyRelationship
  reservation: ToOneRelationship
  taskPhotos: ToManyRelationship
  unit: ToOneRelationship
}

export type JSONApiTaskMap = JSONApiObjectWithRelationshipsMap<
  TaskAttributes,
  TaskRelationships
>

/**********************************************************
 * Assignment Changes
 *********************************************************/

export type JSONApiAssignmentChangeMap = JSONApiObjectWithRelationshipsMap<
  AssignmentChangeAttributes,
  { clean: ToOneRelationship; housekeeper: ToOneRelationship }
>

export type AssignmentChangeAction = 'assign' | 'unassign' | 'reject'

export type AssignmentChangeAttributes = {
  action: AssignmentChangeAction
  changedAt: string
  changedById: string
  cleanId: string
  housekeeperId: string
}

export type RawAssignmnentChange = JSONApiObject<AssignmentChangeAttributes>

export type AssignmentChange = { id: string } & AssignmentChangeAttributes
