import { AxiosError } from 'axios'
import React from 'react'

import {
  UnitReservationServiceResponse,
  UnitReservationAttributes,
} from 'app/hkhub/store/reservations'
import { reservationsService } from 'app/hkhub/store/reservations/reservations.service'

export type UnitReservation = UnitReservationAttributes & {
  id: string
}

type UseUnitReservation = {
  error: AxiosError | null
  loading: boolean
  reservations: UnitReservation[] | null
}

export const processReservationNormalizedData = (
  response: Awaited<UnitReservationServiceResponse>,
): UnitReservation[] | null => {
  const reservation = response.normalized.reservation
  if (!reservation) {
    return null
  }

  return Object.entries(reservation).map(([_, res]) => ({
    checkIn: res.attributes.checkIn,
    checkOut: res.attributes.checkOut,
    id: String(res.id),
  }))
}

export const useUnitReservations = (
  date: string,
  unitId?: string,
): UseUnitReservation => {
  const [reservations, setReservations] = React.useState<
    UnitReservation[] | null
  >(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<AxiosError | null>(null)

  React.useEffect(() => {
    if (unitId) {
      setLoading(true)
      reservationsService
        .fetchReservationForDateAndUnit(date, unitId)
        .then(response => {
          setReservations(processReservationNormalizedData(response))
        })
        .catch(error => {
          setError(error)
          setReservations(null)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [unitId, date])

  return {
    error,
    loading,
    reservations,
  }
}
