import { createSelector } from '@reduxjs/toolkit'

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import { ApplicationState } from 'app/hkhub/store/store'

import {
  ReservationAttributeNames,
  Reservation,
  ReservationsState,
} from '../reservations.types'

export const getReservationById = createSelector(
  (state: ApplicationState) => state.reservations,
  (_: unknown, id: string) => id,
  (
    reservationsState: ReservationsState,
    reservationId: string,
  ): Reservation | undefined => {
    const rawReservation = reservationsState.data?.[reservationId]

    return (
      rawReservation &&
      transformNormalizedToTyped<Reservation>(
        rawReservation,
        ReservationAttributeNames,
      )
    )
  },
)
