import React from 'react'
import { useSelector } from 'react-redux'
import { SingleValue } from 'react-select'

import { Button, Alert, useToast } from 'packages/common'
import { useDatePickerModal } from 'packages/common/src/DatePicker/useDatePickerModal'
import {
  createDateObject,
  createDateString,
  format,
  DateFormat,
} from 'packages/utils/dateHelpers'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { useAppDispatch } from 'app/hkhub/store/hooks'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { getSortedHks } from 'app/hkhub/store/housekeepers/selectors'
import { ApplicationState } from 'app/hkhub/store/store'
import { Unit } from 'app/hkhub/store/units'
import { createVisit } from 'app/hkhub/store/visits/actions/createVisit'

import { CreateVisitWarnings } from '../CreateVisitWarnings/CreateVisitWarnings'
import { useUnitReservations } from '../hooks/useUnitReservations'
import { useUnitVisits } from '../hooks/useUnitVisits'
import { useUserStandardAvailability } from '../hooks/useUserStandardAvailability'
import { PastInspections } from '../PastInspections'
import { UnitSearchSelector } from '../UnitSearchSelector'
import { VisitAssignment } from '../VisitAssignment'
import { isExistingVisitError } from './CreateVisitModalForm.helpers'

import styles from './CreateVisitModalForm.module.scss'

export enum CreateVisitModalFormTestIds {
  addVisitButton = 'CreateVisitModalForm__addVisitButton',
  cancelButton = 'CreateVisitModalForm__cancelButton',
  container = 'CreateVisitModalForm__container',
  datePickerButton = 'CreateVisitModalForm__datePickerButton',
  unitSelectContainer = 'CreateVisitModalForm__unitSelectContainer',
}

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    addVisit: ut(Slugs.addVisit),
    availabilityFetchingError: ut(
      Slugs.createVisitUserStandardAvailabilityFetchingError,
    ),
    cancel: ut(Slugs.cancel),
    date: ut(Slugs.date),
    existingVisitError: ut(Slugs.createVisitExistingVisitError),
    genericError: ut(Slugs.createVisitGenericError),
    reservationError: ut(Slugs.createVisitReservationFetchingError),
    success: ut(Slugs.addVisitSuccessMessage),
    today: ut(Slugs.today),
    unit: ut(Slugs.unit),
  }
}

const getFormattedDate = (date: Date): string => {
  return format(date, 'LLLL d')
}

export type CreateVisitModalFormProps = {
  onClose: () => void
}

export const CreateVisitModalForm: React.FC<CreateVisitModalFormProps> = ({
  onClose,
}) => {
  const strings = useTranslations()
  const [unit, setUnit] = React.useState<Unit | null>(null)
  const [assignedHousekeeper, setAssignedHousekeeper] =
    React.useState<Housekeeper | null>(null)
  const [selectedDate, setSelectedDate] = React.useState<Date>(
    createDateObject(),
  )
  const dispatch = useAppDispatch()
  const { showToast } = useToast()
  const housekeepers = useSelector((state: ApplicationState) =>
    getSortedHks(state?.housekeepers),
  )

  const {
    isAvailable: userIsAvailable,
    isLoading: userStandardAvailabilityLoading,
    hasError: userStandardAvailabilityError,
  } = useUserStandardAvailability(selectedDate, assignedHousekeeper?.user.id)

  const { userHasExistingVisit, otherVisitUser, unitHasUnassignedVisit } =
    useUnitVisits(unit?.id || '', selectedDate)

  const {
    reservations,
    loading: reservationLoading,
    error: reservationError,
  } = useUnitReservations(createDateString(selectedDate), unit?.id)

  const [createVisitState, createVisitFn] = useAsyncFnWithReset(async () => {
    if (!unit) return

    return dispatch(
      createVisit(
        {
          date: selectedDate,
          housekeeperId: assignedHousekeeper?.id,
          unitId: unit.id,
        },
        {
          onSuccess: () => {
            showToast({ message: strings.success })
            onClose()
          },
        },
      ),
    )
  }, [
    unit,
    assignedHousekeeper,
    selectedDate,
    dispatch,
    showToast,
    strings.success,
    onClose,
  ])

  const onClickAddVisit = React.useCallback(() => {
    createVisitFn()
  }, [createVisitFn])

  const onUnitChange = React.useCallback(
    (unit: SingleValue<Unit>) => {
      setUnit(unit)
      setAssignedHousekeeper(
        housekeepers.find(hk => hk.user.id === unit?.managerUserId) || null,
      )
    },
    [housekeepers],
  )

  const { showDatePickerModal } = useDatePickerModal({
    disableBeforeDate: createDateObject(),
    onDateChange: date => {
      if (date) {
        setSelectedDate(date)
      }
    },
    selectedDate,
  })

  const isToday =
    format(selectedDate, DateFormat.ApiUtcShort) ===
    format(createDateObject(), DateFormat.ApiUtcShort)

  const isLoading = userStandardAvailabilityLoading || reservationLoading
  const hasError =
    userStandardAvailabilityError ||
    !!reservationError ||
    !!createVisitState.error

  return (
    <div
      className={styles.container}
      data-testid={CreateVisitModalFormTestIds.container}
    >
      <div className={styles.contentContainer}>
        <h3 className={styles.modalTitle}>{strings.addVisit}</h3>
        <div className={styles.todayDateContainer}>
          <label className={styles.label}>{strings.date}</label>
          <Button
            buttonType="utility"
            onClick={showDatePickerModal}
            dataTestId={CreateVisitModalFormTestIds.datePickerButton}
            block={true}
          >
            {isToday
              ? `${strings.today}, ${getFormattedDate(selectedDate)}`
              : getFormattedDate(selectedDate)}
          </Button>
        </div>
        <div
          className={styles.unitSelectContainer}
          data-testid={CreateVisitModalFormTestIds.unitSelectContainer}
        >
          <label className={styles.label}>{strings.unit}</label>
          <UnitSearchSelector onUnitChange={onUnitChange} />
        </div>
        {!!unit && (
          <>
            <VisitAssignment
              housekeeper={assignedHousekeeper}
              setHousekeeper={setAssignedHousekeeper}
              options={housekeepers}
            />
            <CreateVisitWarnings
              userHasExistingVisit={userHasExistingVisit}
              reservations={reservations}
              otherVisitUser={otherVisitUser}
              unitHasUnassignedVisit={unitHasUnassignedVisit}
              selectedDate={selectedDate}
              assignedHousekeeper={assignedHousekeeper}
              isHousekeeperAvailable={userIsAvailable}
              isLoading={isLoading}
            />
            <PastInspections unit={unit} />
          </>
        )}
      </div>
      {!!reservationError && (
        <Alert alertType={'danger'}>{strings.reservationError}</Alert>
      )}
      {!!createVisitState.error && (
        <Alert alertType={'danger'}>
          {isExistingVisitError(createVisitState.error)
            ? strings.existingVisitError
            : strings.genericError}
        </Alert>
      )}
      {userStandardAvailabilityError && (
        <Alert alertType={'danger'}>{strings.availabilityFetchingError}</Alert>
      )}
      <div className={styles.actionsContainer}>
        <Button
          buttonType="secondary"
          onClick={onClose}
          block={true}
          dataTestId={CreateVisitModalFormTestIds.cancelButton}
          isLoading={createVisitState.loading}
        >
          {strings.cancel}
        </Button>
        <Button
          buttonType="primary"
          block={true}
          onClick={onClickAddVisit}
          disabled={
            !unit ||
            !!userHasExistingVisit ||
            !!unitHasUnassignedVisit ||
            !!otherVisitUser ||
            (!!assignedHousekeeper && !userIsAvailable) ||
            isLoading ||
            hasError
          }
          dataTestId={CreateVisitModalFormTestIds.addVisitButton}
          isLoading={createVisitState.loading}
        >
          {strings.addVisit}
        </Button>
      </div>
    </div>
  )
}
