import { createAsyncAction } from 'typesafe-actions'

import { RequestConfig, RequestOptions } from 'packages/utils/store/jsonapi.types'

import { usersService } from '../users.service'
import { NormalizedUsersApiResponse, UsersActionTypes } from '../users.types'

export const fetchCoveragePartnerOptionsAction = createAsyncAction(
  UsersActionTypes.FETCH_COVERAGE_PARTNER_OPTIONS,
  UsersActionTypes.FETCH_COVERAGE_PARTNER_OPTIONS_SUCCESS,
  UsersActionTypes.FETCH_COVERAGE_PARTNER_OPTIONS_FAILURE,
)<
  RequestConfig<NormalizedUsersApiResponse>,
  NormalizedUsersApiResponse,
  Error
>()

export const getParams = (search?: string): RequestOptions => ({
  fields: {
    user: ['first_name','is_homeowner','is_housekeeping_manager','is_superuser','last_name','employee_type','monolith_user_id'],
  },
  filter: {
    eligible_for_search: true,
    exclude_me: true,
    is_active: true,
    search,
  },
  page: { size: 500 },
})

export const fetchCoveragePartnerOptions =
  (search?: string) => async dispatch => {
    try {
      const params = getParams(search)
      const request = () => usersService.fetchUsers(params)
      const result = await dispatch(
        fetchCoveragePartnerOptionsAction.request({ request }),
      )
      dispatch(fetchCoveragePartnerOptionsAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchCoveragePartnerOptionsAction.failure(error))
      throw error
    }
  } 