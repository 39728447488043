import { includes, startCase } from 'lodash/fp'
import React from 'react'
import { useSelector } from 'react-redux'

import { Tooltip, TooltipPosition } from 'packages/common'
import { fullName } from 'packages/grimoire'
import { parseBeds } from 'packages/grimoire/src/unit/unit.helpers'
import { IconName, IconTextPosition, SvgIcon } from 'packages/iconic'
import { Breakpoint } from 'packages/styles'

import { unitStatusTextMap } from 'app/hkhub/components/schedule/components/units/utils/unitSchedule.utils'
import { useDeepCleanUpNextConfig } from 'app/hkhub/components/schedule/helpers/deepCleans.helpers'
import { useI18n, Slugs } from 'app/hkhub/i18n'
import { getOnCallUserForUnit } from 'app/hkhub/store/oncall_user/selectors/getOnCallUserForUnit'
import { ApplicationState } from 'app/hkhub/store/store'
import { Unit } from 'app/hkhub/store/units/units.types'

import { St } from './UnitScheduleRowHeader.styles'

export enum UnitScheduleRowHeaderTestIds {
  overdueIcon = 'UnitScheduleRowheader__overdueIcon',
}

const useTranslations = (
  alertType: null | 'danger' | 'warning',
  overdueTicketCount: number,
  unit: Unit,
) => {
  const { ut, tp } = useI18n()

  return {
    deepCleanDueText: `Deep Clean ${
      alertType === 'danger' ? 'Overdue' : 'Due'
    }`,
    overdueTooltipText: tp(
      Slugs.overdueToolTipTextWithNumber,
      overdueTicketCount,
      false,
    ),
    status: startCase(ut(unitStatusTextMap[unit.realtimeStatus])),
  }
}

export type UnitScheduleRowHeaderProps = {
  breakpoint: Breakpoint
  onOpenUnitDrawer: () => void
  overdueTicketCount: number
  unit: Unit
}

export const UnitScheduleRowHeader: React.FC<UnitScheduleRowHeaderProps> = ({
  breakpoint,
  onOpenUnitDrawer,
  overdueTicketCount,
  unit,
}) => {
  const deepCleanAlertConfig = useDeepCleanUpNextConfig(unit.nextDeepCleanDueAt)
  const oncallManager = useSelector((state: ApplicationState) =>
    getOnCallUserForUnit(state, unit.id),
  )

  const alertType: null | 'danger' | 'warning' = React.useMemo(() => {
    if (
      !deepCleanAlertConfig ||
      !includes(deepCleanAlertConfig.alertType)(['danger', 'warning'])
    ) {
      return null
    }

    // this type casting is safe because of the includes check in the above if statement
    return deepCleanAlertConfig.alertType as 'danger' | 'warning'
  }, [deepCleanAlertConfig])

  const strings = useTranslations(alertType, overdueTicketCount, unit)

  const alertText: string | undefined = React.useMemo(() => {
    if (!alertType || breakpoint === 'SM') return
    return strings.deepCleanDueText
  }, [alertType, breakpoint, strings])

  return (
    <St.ScheduleRowHeader
      breakpoint={breakpoint}
      onClick={onOpenUnitDrawer}
      overdueTicketCount={overdueTicketCount}
    >
      <St.ScheduleRowHeaderUnitCode breakpoint={breakpoint}>
        {unit.unitCode}
      </St.ScheduleRowHeaderUnitCode>

      {overdueTicketCount > 0 && (
        <Tooltip
          message={strings.overdueTooltipText}
          position={TooltipPosition.Right}
        >
          <St.OverdueIcon
            centerItems={true}
            dataTestId={UnitScheduleRowHeaderTestIds.overdueIcon}
            icon={IconName.alertTriangle}
            size={12}
            text={overdueTicketCount.toString()}
            textPosition={IconTextPosition.Left}
          />
        </Tooltip>
      )}

      <St.ScheduleRowHeaderUnitName breakpoint={breakpoint}>
        {unit.name}
      </St.ScheduleRowHeaderUnitName>

      <St.ScheduleRowHeaderUnitInfo>
        <SvgIcon
          centerItems={true}
          icon={IconName.bed}
          size={14}
          textFontSize={14}
          text={parseBeds(unit)}
          textPosition={IconTextPosition.Left}
        />
        <SvgIcon
          centerItems={true}
          icon={IconName.bath}
          size={14}
          text={`${unit?.bathrooms || '?'}`}
          textFontSize={14}
          textPosition={IconTextPosition.Left}
        />
      </St.ScheduleRowHeaderUnitInfo>

      {alertType && (
        <St.DeepCleanAlertContainer breakpoint={breakpoint}>
          <St.DeepCleanAlert breakpoint={breakpoint} type={alertType}>
            <SvgIcon
              centerItems={true}
              icon={IconName.bucketClean}
              size={breakpoint === 'LG' ? 16 : 14}
              text={alertText}
              textFontSize={14}
            />
          </St.DeepCleanAlert>
        </St.DeepCleanAlertContainer>
      )}
      {oncallManager && (
        <St.OnCallManagerContainer breakpoint={breakpoint}>
          <St.OnCallManagerIcon
            centerItems={true}
            icon={
              oncallManager.oncallAt[unit.id.toString()] ===
              'standard_availability'
                ? IconName.user2
                : IconName.userCircleSwitchDanger
            }
            size={breakpoint === 'LG' ? 16 : 14}
            text={fullName(oncallManager)}
            textFontSize={14}
          />
          <St.OnCallManagerIcon
            centerItems={true}
            icon={IconName.phone}
            size={breakpoint === 'LG' ? 16 : 14}
            text={oncallManager.mobilePhone}
          />
        </St.OnCallManagerContainer>
      )}
      <St.ScheduleRowHeaderStatus breakpoint={breakpoint}>
        {strings.status}
      </St.ScheduleRowHeaderStatus>
    </St.ScheduleRowHeader>
  )
}
