import { produce } from 'immer'
import { merge } from 'lodash/fp'
import { ActionType, getType } from 'typesafe-actions'

import { setAuthUser } from 'packages/utils/misc'

import { fetchCleanAction } from '../cleans/actions'
import {
  fetchTicketByIdAction,
  fetchTicketsByZoneAndDateAction,
} from '../tickets/actions'
import { fetchUnitByIdStrict } from '../units/actions'
import { fetchZoneAction } from '../zones/actions'
import { fetchCurrentUserAction, searchUsersAction } from './actions'
import { fetchCoveragePartnerAction } from './actions/fetchCoveragePartner'
import { UsersState, RawUser } from './users.types'
import { emptyNormalizedUsersData } from './users.utils'

export const SENSITIVE_USER_ATTRS = ['address']

const initialState: UsersState = {
  authUserId: '',
  data: {},
  error: undefined,
  isLoading: false,
  searchResults: {
    user: {},
  },
}

const actions = {
  fetchCleanAction,
  fetchCoveragePartnerAction,
  fetchCurrentUserAction,
  fetchTicketByIdAction,
  fetchTicketsByZoneAndDateAction,
  fetchZoneAction,
  searchUsersAction,
}

type UsersActionTypes = ActionType<typeof actions>

export const usersReducer = (
  state = initialState,
  action: UsersActionTypes,
): UsersState =>
  produce(state, (draft: UsersState) => {
    switch (action.type) {
      case getType(fetchTicketByIdAction.success):
      case getType(fetchTicketsByZoneAndDateAction.success):
      case fetchUnitByIdStrict.fulfilled.toString():
      case getType(fetchCleanAction.success):
      case getType(fetchZoneAction.success): {
        const users = ('normalized' in action.payload ? action.payload.normalized?.user : {}) || {}

        const sanitizedUsers = (Object.values(users) as RawUser[]).map(user =>
          produce(user, draft => {
            SENSITIVE_USER_ATTRS.forEach(
              field => delete draft?.attributes[field],
            )
          }),
        )

        sanitizedUsers.forEach((user: RawUser) => {
          draft.data[user.id] = merge(state.data[user.id] ?? {}, user)
        })

        return
      }

      case getType(searchUsersAction.failure): {
        draft.isLoading = false
        draft.error = action.payload
        return
      }

      case getType(searchUsersAction.success): {
        const searchResultsData =
          action.payload?.normalized || emptyNormalizedUsersData

        draft.searchResults = {
          user: searchResultsData.user || {},
        }

        draft.isLoading = false
        return
      }

      case getType(fetchCurrentUserAction.success): {
        const users =
          action.payload.normalized?.user || emptyNormalizedUsersData

        const user = Object.values(users)[0]
        // clone user data with any sensitive fields removed
        const sanitizedUser = produce(user, draft => {
          SENSITIVE_USER_ATTRS.forEach(field => delete draft.attributes[field])
        })

        draft.authUserId = user.id
        draft.data[user.id] = sanitizedUser
        setAuthUser(sanitizedUser)

        // add the authenticated user ID to Google Tag Manager's variables
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataLayer = (window as any).dataLayer
        if (dataLayer) {
          dataLayer.push({ userId: user.id })
        }

        return
      }

      case getType(fetchCoveragePartnerAction.success): {
        const users = ('normalized' in action.payload ? action.payload.normalized?.user : {}) || {}
        const [userId, coveragePartner] = Object.entries(users)[0] || []
        
        if (userId && coveragePartner) {
          // Update the user in the store with their coverage partner relationship
          const user = draft.data[userId] as RawUser
          if (user) {
            user.relationships = {
              ...user.relationships,
              currentCoveragePartner: {
                data: { id: coveragePartner.id, type: 'user' },
              },
            }
          }

          // Store or update the coverage partner user data
          if (!state.data[coveragePartner.id]) {
            // If coverage partner is not in store, store it without sensitive data
            const sanitizedPartner = produce(coveragePartner, draft => {
              SENSITIVE_USER_ATTRS.forEach(field => delete draft?.attributes[field])
            })
            draft.data[coveragePartner.id] = sanitizedPartner
          } else {
            // If exists, merge with existing data
            draft.data[coveragePartner.id] = merge(
              state.data[coveragePartner.id],
              coveragePartner,
            )
          }
        }
        
        return
      }

      case getType(fetchCoveragePartnerAction.failure): {
        draft.error = action.payload
        return
      }
    }
  })
