import { createAsyncAction } from 'typesafe-actions'

import { makeRelationship } from 'packages/utils/store'
import { RequestConfig, RequestOptions } from 'packages/utils/store/jsonapi.types'

import { usersService } from '../users.service'
import { NormalizedUsersApiResponse, UsersActionTypes } from '../users.types'

export const createCoveragePartnerAction = createAsyncAction(
  UsersActionTypes.CREATE_COVERAGE_PARTNER,
  UsersActionTypes.CREATE_COVERAGE_PARTNER_SUCCESS,
  UsersActionTypes.CREATE_COVERAGE_PARTNER_FAILURE,
)<
  RequestConfig<NormalizedUsersApiResponse>,
  NormalizedUsersApiResponse,
  Error
>()

type CreateCoveragePartnerData = {
  coveredUserId: string
  coveringUserId: string
}

const getParams = (postData: CreateCoveragePartnerData): RequestOptions => ({
  data: {
    attributes: {},
    relationships: {
      user_covered: makeRelationship('user', postData.coveredUserId),
      user_covering: makeRelationship('user', postData.coveringUserId),
    },
    type: 'coverage_partner',
  },
})

export const createCoveragePartner = (postData: CreateCoveragePartnerData) => async (dispatch) => {
  try {
    const params = getParams(postData)
    const request = () => usersService.createCoveragePartner(params)
    const result = await dispatch(
      createCoveragePartnerAction.request({ request }),
    )
    dispatch(createCoveragePartnerAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(createCoveragePartnerAction.failure(error))
    throw error
  }
} 