import { produce } from 'immer'
import { ActionType, getType } from 'typesafe-actions'

import { updateCleanAction } from 'app/hkhub/store/cleans/actions'

import {
  setUnitDrawerStateAction,
  setLoadingCleanIdAction,
  setCleanDrawerStateAction,
  setVisitDrawerStateAction,
  setCoveragePartnerDrawerStateAction,
} from './actions'
import { setTicketDrawerStateAction } from './actions/setTicketDrawerState'
import { UiState } from './ui.types'

export const initialState: UiState = {
  drawers: {
    cleanDrawer: { cleanId: '', isOpen: false },
    coveragePartnerDrawer: { isOpen: false, userId: '', userName: undefined },
    ticketDrawer: { isOpen: false, ticketId: '' },
    unitDrawer: { isOpen: false, unitId: '' },
    visitDrawer: { isOpen: false, visitId: '' },
  },
  loadingCleanId: '',
}

const actions = {
  setCleanDrawerStateAction,
  setCoveragePartnerDrawerStateAction,
  setLoadingCleanIdAction,
  setTicketDrawerStateAction,
  setUnitDrawerStateAction,
  setVisitDrawerStateAction,
  updateCleanAction,
}

type UiReducerActionsTypes = ActionType<typeof actions>

export const uiReducer = (
  state = initialState,
  action: UiReducerActionsTypes,
): UiState =>
  produce(state, (draft: UiState) => {
    switch (action.type) {
      case getType(setUnitDrawerStateAction): {
        const { isOpen = false, unitId = '' } = action.payload
        draft.drawers.unitDrawer.isOpen = isOpen
        draft.drawers.unitDrawer.unitId = unitId
        return
      }

      case getType(setCleanDrawerStateAction): {
        const { isOpen = false, cleanId = '' } = action.payload
        draft.drawers.cleanDrawer.isOpen = isOpen
        draft.drawers.cleanDrawer.cleanId = cleanId
        return
      }

      case getType(setTicketDrawerStateAction): {
        const { isOpen = false, ticketId = '' } = action.payload
        draft.drawers.ticketDrawer.isOpen = isOpen
        draft.drawers.ticketDrawer.ticketId = ticketId
        return
      }

      case getType(setVisitDrawerStateAction): {
        const { isOpen = false, visitId = '' } = action.payload
        draft.drawers.visitDrawer.isOpen = isOpen
        draft.drawers.visitDrawer.visitId = visitId
        return
      }

      case getType(setCoveragePartnerDrawerStateAction): {
        const { isOpen = false, userId = '', userName } = action.payload
        draft.drawers.coveragePartnerDrawer.isOpen = isOpen
        draft.drawers.coveragePartnerDrawer.userId = userId
        draft.drawers.coveragePartnerDrawer.userName = userName
        return
      }

      case getType(setLoadingCleanIdAction): {
        draft.loadingCleanId = action.payload || ''
        return
      }

      case getType(actions.updateCleanAction.success):
      case getType(actions.updateCleanAction.failure): {
        draft.loadingCleanId = ''
        return
      }
    }
  })
