import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { getHousekeepersByManagerId } from '../../housekeepers/selectors'
import { usersService } from '../users.service'
import { NormalizedUsersApiResponse, UsersActionTypes } from '../users.types'

export const fetchCoveragePartnerAction = createAsyncAction(
  UsersActionTypes.FETCH_COVERAGE_PARTNER,
  UsersActionTypes.FETCH_COVERAGE_PARTNER_SUCCESS,
  UsersActionTypes.FETCH_COVERAGE_PARTNER_FAILURE,
)<
  RequestConfig<NormalizedUsersApiResponse>,
  NormalizedUsersApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  include: ['current_coverage_partner'],
})

export const fetchCoveragePartner = (userId: string) => async (dispatch, getState) => {
  try {
    // First fetch the user to get their coverage partner relationship
    const request = usersService.fetchUserCoveragePartner.bind(
      null,
      userId,
      getParams(),
    )
    const result = await dispatch(
      fetchCoveragePartnerAction.request({ request }),
    )

    // Check if we need to fetch the coverage partner's data
    const coveragePartnerId = result.normalized?.user?.[userId]?.relationships?.currentCoveragePartner?.data?.id
    const state = getState()
    const housekeepers = getHousekeepersByManagerId(state, userId)
    const isHousekeeper = housekeepers.some(hk => hk.user.id === coveragePartnerId)

    if (coveragePartnerId && !state.users.data[coveragePartnerId] && !isHousekeeper) {
      // Fetch the coverage partner's data
      const partnerRequest = usersService.fetchUserCoveragePartner.bind(
        null,
        coveragePartnerId,
        {},
      )
      const partnerResult = await partnerRequest()
      // Dispatch partner data first
      await dispatch(fetchCoveragePartnerAction.success(partnerResult))
    }

    // Then dispatch the original result
    dispatch(fetchCoveragePartnerAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchCoveragePartnerAction.failure(error))
    throw error
  }
} 