import { get } from 'lodash'

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  NormalizedOnCallUserApiResponse,
  OnCallUserApiResponse,
  OnCallUserServiceResponse,
} from './onCallUser.types'

const URL = '/oncall_user'

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptyOnCallUserResponse: NormalizedOnCallUserApiResponse =
  Object.freeze({
    normalized: {
      oncallUserApi: {},
    },
    raw: { data: [] },
  })

export const onCallUsersService = {
  /**
   * Performs a GET request for OnCall Users
   * @param params
   */
  async fetchOnCallUsers(
    params: RequestOptions = {},
  ): OnCallUserServiceResponse {
    const response: OnCallUserApiResponse = await api.get(`${URL}`, { params })
    return get(response, 'data', emptyOnCallUserResponse)
  },
}
