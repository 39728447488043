import styled from '@emotion/styled'
import React from 'react'
import { FormatOptionLabelMeta } from 'react-select'

import {
  DEFAULT_WILDCARD,
  splitAndInsertWildcards,
} from 'packages/utils/searchHelpers'

import { Unit } from 'app/hkhub/store/units'

const UnitOption = styled.span<{ isSearchMatch: boolean }>`
  font-weight: ${({ isSearchMatch }) => (isSearchMatch ? 'bold' : 'normal')};
`

export const buildUnitString = ({
  name,
  unitCode,
  city,
  state,
}: Unit): string => {
  return `${name} (${unitCode}, ${city}, ${state})`
}

type UnitFilterOption = {
  data: Unit
  label: string
  value: string
}

export const filterUnits = (
  option: UnitFilterOption,
  inputValue: string,
): boolean => {
  const { data: unit } = option
  if (
    inputValue === '' ||
    unit.name.toLowerCase().includes(inputValue.toLowerCase()) ||
    unit.unitCode.toLowerCase().includes(inputValue.toLowerCase()) ||
    unit.city.toLowerCase().includes(inputValue.toLowerCase()) ||
    unit.state.toLowerCase().includes(inputValue.toLowerCase())
  ) {
    return true
  }

  return false
}

export enum UnitSelectorHelperTestIds {
  match = 'UnitSelectorHelperTestIds__match',
  noMatch = 'UnitSelectorHelperTestIds__no_match',
}

export const getHighlightedUnitSearchResults = (
  unit: Unit,
  formatOptionLabelMeta: FormatOptionLabelMeta<Unit>,
): React.ReactNode => {
  const { inputValue } = formatOptionLabelMeta

  const searchString = inputValue.trim()
  const fullUnitString = buildUnitString(unit)

  if (!searchString) {
    return fullUnitString
  }

  const splitByMatch = splitAndInsertWildcards(fullUnitString, searchString)

  return splitByMatch.reduce((accum, val, i) => {
    if (val === DEFAULT_WILDCARD) {
      return accum // do not make a node for strings matching the wildcard
    }

    // bold it if the previous string was '*'
    const isSearchMatch = i > 0 && splitByMatch[i - 1] === DEFAULT_WILDCARD

    return accum.concat(
      <UnitOption
        data-testid={
          isSearchMatch
            ? UnitSelectorHelperTestIds.match
            : UnitSelectorHelperTestIds.noMatch
        }
        isSearchMatch={isSearchMatch}
        key={i}
      >
        {val}
      </UnitOption>,
    )
  }, [] as React.ReactNode[])
}
