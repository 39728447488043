import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Options } from 'react-select'

import { useToast } from 'packages/common'
import { HkEmployeeType, User } from 'packages/grimoire'
import { useAsyncFnWithReset } from 'packages/utils/hooks'
import { logError } from 'packages/wiretap/logging'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { AppDispatch, ApplicationState } from 'app/hkhub/store/store'
import { setCoveragePartnerDrawerStateAction } from 'app/hkhub/store/ui/actions'
import { getCoveragePartnerDrawerState } from 'app/hkhub/store/ui/selectors/getCoveragePartnerDrawerState'
import { createCoveragePartner } from 'app/hkhub/store/users/actions/createCoveragePartner'
import { fetchCoveragePartner } from 'app/hkhub/store/users/actions/fetchCoveragePartner'
import { fetchCoveragePartnerOptions } from 'app/hkhub/store/users/actions/fetchCoveragePartnerOptions'
import { getCoveragePartner } from 'app/hkhub/store/users/selectors/getCoveragePartner'
import { RawUser } from 'app/hkhub/store/users/users.types'
import { hydrateRawUser } from 'app/hkhub/store/users/users.utils'

import { CoveragePartnerDrawer } from './CoveragePartnerDrawer'

export const CoveragePartnerDrawerContainer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { showToast } = useToast()
  const { t } = useI18n()
  const { isOpen, userId, userName } = useSelector(getCoveragePartnerDrawerState)

  const currentPartner = useSelector((state: ApplicationState) => {
    const rawUser = userId ? getCoveragePartner(state, userId) : undefined
    return rawUser ? {
      ...hydrateRawUser(rawUser),
      employeeType: HkEmployeeType.employee,
      isHousekeepingManager: true,
    } : undefined
  })

  const [selectedUser, setSelectedUser] = useState<User | undefined>(currentPartner)

  // Add a ref to track previous isOpen and update useEffect
  const prevIsOpen = React.useRef(isOpen)

  useEffect(() => {
    if (isOpen && !prevIsOpen.current) {
      setSelectedUser(currentPartner)
    }
    
    prevIsOpen.current = isOpen
  }, [isOpen, currentPartner])

  const [createCoveragePartnerState, createCoveragePartnerFn, createCoveragePartnerResetFn] =
    useAsyncFnWithReset(async () => {
      if (!userId || !selectedUser?.id) return

      try {
        await dispatch(createCoveragePartner({
          coveredUserId: userId,
          coveringUserId: selectedUser.id,
        }))

        // Fetch the updated coverage partner data
        await dispatch(fetchCoveragePartner(userId))

        showToast({
          message: t(Slugs.coveragePartnerSuccessMessage),
          toastType: 'success',
        })

        dispatch(
          setCoveragePartnerDrawerStateAction({
            isOpen: false,
            userId: '',
            userName: undefined,
          }),
        )

        setSelectedUser(undefined)
        createCoveragePartnerResetFn()
      } catch (error) {
        const apiError = error?.response?.data?.errors?.[0] || {}
        const { detail, title, code } = apiError
        const errorMessage = detail || title || t(Slugs.coveragePartnerErrorMessage)
        
        showToast({
          message: `${errorMessage}${code ? ` (Error: ${code})` : ''}`,
          toastType: 'danger',
        })
        
        setSelectedUser(currentPartner)
      }
    }, [dispatch, userId, selectedUser, showToast, currentPartner, t])

  const hasUserChanged = useMemo(() => {
    return selectedUser?.id !== currentPartner?.id
  }, [currentPartner?.id, selectedUser?.id])

  const handleClose = useCallback(() => {
    dispatch(
      setCoveragePartnerDrawerStateAction({
        isOpen: false,
        userId: '',
        userName: undefined,
      }),
    )

    setSelectedUser(undefined)
    createCoveragePartnerResetFn()
  }, [dispatch, createCoveragePartnerResetFn])

  const handleSubmit = useCallback(() => {
    createCoveragePartnerFn()
  }, [createCoveragePartnerFn])

  const debounceTimer = React.useRef<NodeJS.Timeout | null>(null)

  const loadOptions = useCallback((
    inputValue: string,
    callback: (options: Options<User>) => void,
  ): void => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current)
    }

    debounceTimer.current = setTimeout(async () => {
      if (inputValue.length >= 3) {
        try {
          const result = await dispatch(fetchCoveragePartnerOptions(inputValue))
          const usersArray: RawUser[] = Object.values(result.user)
          const users = usersArray.map(rawUser => ({
            ...hydrateRawUser(rawUser),
            employeeType: HkEmployeeType.employee,
            isHousekeepingManager: true,
          }))
          callback(users as Options<User>)
        } catch (error) {
          logError(error)
          callback([])
        }
      } else {
        callback([])
      }
    }, 350)
  }, [dispatch])

  const handleUserChange = useCallback((user: User) => {
    createCoveragePartnerResetFn()
    setSelectedUser(user)
  }, [createCoveragePartnerResetFn])

  return (
    <CoveragePartnerDrawer
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmit}
      selectedUser={selectedUser}
      setSelectedUser={handleUserChange}
      loadOptions={loadOptions}
      hasUserChanged={hasUserChanged}
      isLoading={createCoveragePartnerState.loading}
      userName={userName}
    />
  )
} 