import { AxiosResponse } from 'axios'
import snakeCase from 'lodash/snakeCase'

import { HkEmployeeType, isEmployee } from 'packages/grimoire'
import {
  JSONApiObjectMap,
  JSONApiObjectWithRelationshipsMap,
  ToOneRelationship,
  ToManyRelationship,
  RelationshipUpdate,
  NormalizedJSONApiResponse,
  JSONApiObjectWithRelationships,
  JSONApiObject,
} from 'packages/utils/store/jsonapi.types'

import { JSONApiUsersMap, User } from 'app/hkhub/store/users/users.types'
import { JSONApiZonesMap, Zone } from 'app/hkhub/store/zones/zones.types'

/**********************************************************
 * SHARED TYPES
 *********************************************************/
export { HkEmployeeType, isEmployee }

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type HkSearchResultsState = {
  housekeeper: JSONApiHousekeepersMap
  user: JSONApiHousekeepersMap
  zone: JSONApiZonesMap
}

export type HousekeepersState = {
  data: JSONApiHousekeepersMap
  error?: Error
  housekeeperTier: JSONApiHousekeeperTierMap
  isLoading: boolean
  searchResults: HkSearchResultsState
  user: JSONApiUsersMap
}

export enum HousekeepersActionTypes {
  ADD_BORROWED_ZONE_TO_HK = 'HOUSEKEEPERS/API/ADD_BORROWED_ZONE_TO_HK',
  ADD_BORROWED_ZONE_TO_HK_FAILURE = 'HOUSEKEEPERS/ADD_BORROWED_ZONE_TO_HK_FAILURE',
  ADD_BORROWED_ZONE_TO_HK_SUCCESS = 'HOUSEKEEPERS/ADD_BORROWED_ZONE_TO_HK_SUCCESS',

  CLEAR_HK_SEARCH_RESULTS = 'HOUSEKEEPERS/CLEAR_HK_SEARCH_RESULTS',

  FETCH_HOUSEKEEPERS_BY_MANAGER = 'HOUSEKEEPERS/API/FETCH_HOUSEKEEPERS_BY_MANAGER',
  FETCH_HOUSEKEEPERS_BY_MANAGER_FAILURE = 'HOUSEKEEPERS/FETCH_HOUSEKEEPERS_BY_MANAGER_FAILURE',
  FETCH_HOUSEKEEPERS_BY_MANAGER_SUCCESS = 'HOUSEKEEPERS/FETCH_HOUSEKEEPERS_BY_MANAGER_SUCCESS',

  FETCH_HOUSEKEEPERS_BY_ZONE = 'HOUSEKEEPERS/API/FETCH_HOUSEKEEPERS_BY_ZONE',
  FETCH_HOUSEKEEPERS_BY_ZONE_FAILURE = 'HOUSEKEEPERS/FETCH_HOUSEKEEPERS_BY_ZONE_FAILURE',
  FETCH_HOUSEKEEPERS_BY_ZONE_SUCCESS = 'HOUSEKEEPERS/FETCH_HOUSEKEEPERS_BY_ZONE_SUCCESS',

  REMOVE_BORROWED_ZONE_FROM_HK = 'HOUSEKEEPERS/API/REMOVE_BORROWED_ZONE_FROM_HK',
  REMOVE_BORROWED_ZONE_FROM_HK_FAILURE = 'HOUSEKEEPERS/REMOVE_BORROWED_ZONE_FROM_HK_FAILURE',
  REMOVE_BORROWED_ZONE_FROM_HK_SUCCESS = 'HOUSEKEEPERS/REMOVE_BORROWED_ZONE_FROM_HK_SUCCESS',

  SEARCH_HKS = 'HOUSEKEEPERS/API/SEARCH_HKS',
  SEARCH_HKS_FAILURE = 'HOUSEKEEPERS/SEARCH_HKS_FAILURE',
  SEARCH_HKS_SUCCESS = 'HOUSEKEEPERS/SEARCH_HKS_SUCCESS',

  UPDATE_HK_IN_SEARCH_RESULTS = 'HOUSEKEEPERS/API/UPDATE_HK_IN_SEARCH_RESULTS',
  UPDATE_HK_IN_SEARCH_RESULTS_FAILURE = 'HOUSEKEEPERS/UPDATE_HK_IN_SEARCH_RESULTS_FAILURE',
  UPDATE_HK_IN_SEARCH_RESULTS_SUCCESS = 'HOUSEKEEPERS/UPDATE_HK_IN_SEARCH_RESULTS_SUCCESS',
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
type HkResponse = {
  housekeeper: JSONApiHousekeepersMap
  housekeeperTier: JSONApiHousekeeperTierMap
  manager?: JSONApiUsersMap
  relationshipUpdate?: Partial<RelationshipUpdate>
  user: JSONApiUsersMap
  zone?: JSONApiObjectMap<Zone>
}

export type NormalizedHousekeepersApiResponse =
  NormalizedJSONApiResponse<HkResponse>

export type HksApiResponse = AxiosResponse<NormalizedHousekeepersApiResponse>
export type HksServiceResponse = Promise<NormalizedHousekeepersApiResponse>

/**********************************************************
 * HOUSEKEEPER
 *********************************************************/
// TODO: this type could use some clean (e.g. why are these attrs here, instead of on HousekeeperAttributes?)
export type Housekeeper = {
  borrowedZones?: string[]
  id: string
  manager?: User
  tier: HousekeeperTier
  user: User
  zone?: Zone
} & HousekeeperAttributes &
  HousekeeperComputedAttributes

export type HousekeeperComputedAttributes = {
  borrowedInCurrentZones?: boolean
}

export type HousekeeperAttributes = {
  employeeType: HkEmployeeType
}

export const HousekeeperAttributesNames = ['employeeType']

export const HousekeeperApiFields: string[] =
  HousekeeperAttributesNames.map(snakeCase)

export type HousekeeperRelationships = {
  borrowedZones?: ToManyRelationship
  manager?: ToOneRelationship
  tier: ToOneRelationship
  user: ToOneRelationship
  zone?: ToManyRelationship
}

export type RawHousekeeper = JSONApiObjectWithRelationships<
  HousekeeperAttributes,
  HousekeeperRelationships
>

export type JSONApiHousekeepersMap = JSONApiObjectWithRelationshipsMap<
  HousekeeperAttributes,
  HousekeeperRelationships
>

/**********************************************************
 * HOUSEKEEPER TIER
 *********************************************************/
export type HousekeeperTier = {
  id: string
} & HousekeeperTierAttributes

export type HousekeeperTierAttributes = {
  name: string
  rank: number
}

export const HousekeeperTierAttributeNames = ['name', 'rank']

export const HousekeeperTierApiFields =
  HousekeeperTierAttributeNames.map<string>(snakeCase)

export type RawHousekeeperTier = JSONApiObject<HousekeeperTierAttributes>

export type JSONApiHousekeeperTierMap =
  JSONApiObjectMap<HousekeeperTierAttributes>

/**********************************************************
 * SEARCH RESULTS
 *********************************************************/
export type HkSearchResult = {
  borrowedZones: string[]
  id: string
  user: User
  zone?: Zone
}
