import styled from '@emotion/styled'
import React from 'react'
import Select, { StylesConfig } from 'react-select'

import { colors, text } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'

export enum VisitAssignmentTestIds {
  Container = 'VisitAssignment__container',
}

const St = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  `,
  Label: styled.div`
    ${text.fieldLabelMobile};
    margin-bottom: 8px;
  `,
  SwitchContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  Text: styled.div`
    ${text.bodyRegularSmall};
    color: ${colors.dusk80};
    margin-bottom: 8px;
  `,
}

const customSelectStyles: StylesConfig<Housekeeper> = {
  control: styles => ({
    ...styles,
    '&:hover': {
      borderColor: colors.midnight30,
    },
    borderColor: colors.midnight30,
    boxShadow: 'none',
  }),
  dropdownIndicator: styles => ({ ...styles, color: colors.midnight80 }),
  singleValue: styles => ({
    ...styles,
    color: colors.midnight80,
    fontWeight: 600,
  }),
}

export type VisitAssignmentProps = {
  housekeeper: Housekeeper | null
  options: Housekeeper[]
  setHousekeeper: (housekeeper: Housekeeper | null) => void
}

const useTranslation = () => {
  const { ut } = useI18n()
  return {
    assignment: ut(Slugs.assignment),
    selectHousekeeper: ut(Slugs.selectHousekeeper),
  }
}

export const VisitAssignment: React.FC<VisitAssignmentProps> = ({
  housekeeper,
  options,
  setHousekeeper,
}) => {
  const strings = useTranslation()

  const getOptionLabel = React.useCallback((hk: Housekeeper) => {
    return `${hk.user.firstName} ${hk.user.lastName}`
  }, [])

  return (
    <St.Container data-testid={VisitAssignmentTestIds.Container}>
      <St.Label>{strings.assignment}</St.Label>
      <Select
        options={options}
        value={housekeeper}
        getOptionValue={hk => hk.id}
        getOptionLabel={getOptionLabel}
        onChange={setHousekeeper}
        placeholder={strings.selectHousekeeper}
        isClearable={true}
        isSearchable={true}
        isMulti={false}
        styles={customSelectStyles}
      />
    </St.Container>
  )
}
