import styled from '@emotion/styled'
import React from 'react'
import { Options } from 'react-select'

import { Alert, Button, Drawer } from 'packages/common'
import { User } from 'packages/grimoire'
import { colors, text } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'

import CoveragePartnerSelector from './components/CoveragePartnerSelector'

const St = {
  Alert: styled(Alert)`
    margin: 12px;
  `,
  ButtonSection: styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 12px;
  `,
  Drawer: styled(Drawer)`
    width: min(420px, 100vw);
  `,
  DrawerHeader: styled.div`
    align-items: center;
    background-color: ${colors.midnight10};
    display: flex;
    height: 90px;
    justify-content: center;
    margin-bottom: 20px;
  `,
  DrawerHeaderText: styled.h3`
    ${text.bodyBoldSmall};
    color: ${colors.midnight};
    margin-bottom: 0px;
    text-transform: none;
  `,
  Explanation: styled.div`
    ${text.bodyTiny};
    font-style: italic;
    padding: 12px;
  `,
  Header: styled.h2`
    ${text.headingDefault};
    padding: 12px;
    text-transform: uppercase;
  `,
  SelectContainer: styled.div`
    padding: 12px;
  `,
  SelectLabel: styled.div`
    ${text.fieldLabelMobile};
    padding-bottom: 8px;
  `,
}

export enum CoveragePartnerDrawerTestIds {
  cancel = 'CoveragePartnerDrawer__cancel',
  container = 'CoveragePartnerDrawer__container',
  errorAlert = 'CoveragePartnerDrawer__errorAlert',
  selectContainer = 'CoveragePartnerDrawer__selectContainer',
  submit = 'CoveragePartnerDrawer__submit',
}

export type CoveragePartnerDrawerProps = {
  hasUserChanged: boolean
  isLoading: boolean
  isOpen: boolean
  loadOptions: (inputValue: string, callback: (options: Options<User>) => void) => void
  onClose: () => void
  onSubmit: () => void
  selectedUser: User | undefined
  setSelectedUser: (user: User) => void
  userName?: string
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    cancel: t(Slugs.cancel),
    coveragePartnerExplanation: t(Slugs.coveragePartnerExplanation),
    coveragePartnerForTitle: t(Slugs.coveragePartnerTitle),
    coveragePartnerForTitlewithName: ('{{name}} ') + t(Slugs.coveragePartnerTitle),
    coveragePartnerLabel: t(Slugs.coveragePartnerLabel),
    coveragePartnerTitle: t(Slugs.coveragePartnerTitle),
    save: t(Slugs.save),
  }
}

export const CoveragePartnerDrawer: React.FC<CoveragePartnerDrawerProps> = ({
  hasUserChanged,
  isLoading,
  isOpen,
  loadOptions,
  onClose,
  onSubmit,
  selectedUser,
  setSelectedUser,
  userName,
}) => {
  const strings = useTranslations()

  const title = userName 
    ? strings.coveragePartnerForTitlewithName.replace('{{name}}', userName)
    : strings.coveragePartnerTitle

  return (
    <St.Drawer
      isOpen={isOpen}
      afterExit={onClose}
    >
      <St.DrawerHeader>
        <St.DrawerHeaderText>{strings.coveragePartnerForTitle}</St.DrawerHeaderText>
      </St.DrawerHeader>
      <div data-testid={CoveragePartnerDrawerTestIds.container}>
        <St.Header>{title}</St.Header>
        <St.Explanation>
          {strings.coveragePartnerExplanation}
        </St.Explanation>

        <St.SelectContainer data-testid={CoveragePartnerDrawerTestIds.selectContainer}>
          <St.SelectLabel>{strings.coveragePartnerLabel}</St.SelectLabel>
          <CoveragePartnerSelector
            loadOptions={loadOptions}
            onUserSelectionChange={setSelectedUser}
            selectedUser={selectedUser}
          />
        </St.SelectContainer>

        <St.ButtonSection>
          <Button
            block={true}
            buttonType="utility"
            dataTestId={CoveragePartnerDrawerTestIds.cancel}
            onClick={onClose}
          >
            {strings.cancel}
          </Button>
          <Button
            block={true}
            dataTestId={CoveragePartnerDrawerTestIds.submit}
            disabled={!hasUserChanged}
            isLoading={isLoading}
            onClick={onSubmit}
          >
            {strings.save}
          </Button>
        </St.ButtonSection>
      </div>
    </St.Drawer>
  )
} 