import { createAsyncThunk } from '@reduxjs/toolkit'
import { IJsonApiReference } from 'json-api-normalizer'

import { fetchHousekeepersByManager } from '../../housekeepers/actions/fetchHousekeepersByManager'
import { NormalizedHousekeepersApiResponse } from '../../housekeepers/housekeepers.types'
import { fetchCoveragePartner } from '../../users/actions/fetchCoveragePartner'

export const fetchDataForStaff = createAsyncThunk(
  'highlights/api/fetchDataForStaff',
  async ({ userId }: { userId: string }, { dispatch }) => {
    // Fetch housekeepers managed by the current user
    const housekeeperResult = await dispatch(fetchHousekeepersByManager(userId)) as NormalizedHousekeepersApiResponse['normalized']

    if (!housekeeperResult.housekeeper) {
      return {
        housekeepers: {},
      }
    }

    // Get unique zone IDs from all housekeepers and collect user IDs
    const housekeepers = housekeeperResult.housekeeper
    const uniqueZoneIds = new Set<string>()
    const housekeeperUserIds = new Set<string>()
    
    Object.values(housekeepers).forEach(housekeeper => {
      // Collect zone IDs
      const zoneData = housekeeper.relationships?.zone?.data as IJsonApiReference | IJsonApiReference[] | undefined
      if (zoneData) {
        if (Array.isArray(zoneData)) {
          zoneData.forEach(zone => uniqueZoneIds.add(zone.id))
        } else {
          uniqueZoneIds.add(zoneData.id)
        }
      }

      // Collect user IDs
      const userData = housekeeper.relationships?.user?.data as IJsonApiReference | undefined
      if (userData) {
        housekeeperUserIds.add(userData.id)
      }
    })

    // Fetch coverage partner data for each housekeeper
    const coveragePartnerPromises = Array.from(housekeeperUserIds).map(userId =>
      dispatch(fetchCoveragePartner(userId)),
    )

    // Wait for all promises to resolve
    await Promise.all([...coveragePartnerPromises])

    return {
      housekeepers: housekeeperResult,
    }
  },
)
