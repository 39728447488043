import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import { withMaintenanceMode } from 'packages/common'
import { SegmentTracker } from 'packages/wiretap/src/tracking/SegmentTracker'

import { AdminPage } from './admin/AdminPage'
import { ImpersonationPanel } from './admin/components/ImpersonationPanel'
import { NotFoundPage } from './core/NotFoundPage'
import { Layout } from './v2/Layout'
import { OverviewPage } from './v2/pages/OverviewPage'
import { ReviewPage } from './v2/pages/ReviewPage'
import { StaffPage } from './v2/pages/StaffPage'
import { UnitReviewsPage } from './v2/pages/UnitReviewsPage'
import { ZoneRouter } from './zone/Zone.router'

export type HkHubRootProps = {
  initialUrl: string
}

export const HkHubRoot: React.FC<HkHubRootProps> = ({ initialUrl }) => {
  return (
    <>
      <ImpersonationPanel />
      <Routes>
        {/* ROUTES WITH "/" PREFIX */}
        <Route path="/v2" element={<Layout />}>
          <Route
            index={true}
            element={<Navigate to="overview" replace={true} />}
          />
          <Route path="overview" element={<OverviewPage />} />
          <Route path="review/:reviewId" element={<ReviewPage />} />
          <Route path="reviews" element={<UnitReviewsPage />} />
          <Route path="staff" element={<StaffPage />} />
        </Route>
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/zone/*" element={<ZoneRouter />} />
        <Route
          path="/oauth/callback"
          element={<Navigate to={initialUrl || '/zone'} replace={true} />}
        />
        <Route path="/" element={<Navigate to="/zone" replace={true} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <SegmentTracker />
    </>
  )
}

export default withMaintenanceMode(HkHubRoot)
