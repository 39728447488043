import { AxiosResponse } from 'axios'

import { User, UserAttributeNames, UserAttributes } from 'packages/grimoire'
import {
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
} from 'packages/utils/store/jsonapi.types'

/**********************************************************
 * UTILITY TYPES
 *********************************************************/
export type OnCallUserByIdMap = {
  [unitId: string]: OnCallUser
}

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type OnCallUserState = {
  data: JSONApiOnCallUserMap
  error?: Error
  isLoading: boolean
}

export enum OnCallUserActionTypes {
  FETCH_ON_CALL_USERS = 'oncall_user/api/fetchOnCallUsers/pending',
  FETCH_ON_CALL_USERS_FAILURE = 'ONCALL_USER/FETCH_ON_CALL_USERS_FAILURE',
  FETCH_ON_CALL_USERS_SUCCESS = 'ONCALL_USER/FETCH_ON_CALL_USERS_SUCCESS',
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type OnCallUserResponse = {
  oncallUserApi?: JSONApiOnCallUserMap
}

export type NormalizedOnCallUserApiResponse =
  NormalizedJSONApiResponse<OnCallUserResponse>
export type OnCallUserApiResponse =
  AxiosResponse<NormalizedOnCallUserApiResponse>
export type OnCallUserServiceResponse = Promise<NormalizedOnCallUserApiResponse>

/**********************************************************
 * ONCALL USER
 *********************************************************/
export type OnCallAtData = {
  [unitId: string]: string
}

export type OnCallUser = User & {
  oncallAt: OnCallAtData
}

export type AllOnCallUserAttributes = UserAttributes & {
  oncallAt: OnCallAtData
}

export type JSONApiOnCallUserMap = JSONApiObjectWithRelationshipsMap<
  AllOnCallUserAttributes,
  {}
>

export const OnCallUserAttributeNames = [...UserAttributeNames, 'oncallAt']
