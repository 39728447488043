import { createSelector } from 'reselect'

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import { ApplicationState } from '../../store'
import {
  OnCallUser,
  OnCallUserAttributeNames,
  OnCallUserState,
} from '../onCallUser.types'

export const getOnCallUsers = createSelector(
  (state: ApplicationState) => state.onCallUsers,
  (onCallUsersState: OnCallUserState): OnCallUser[] => {
    return Object.keys(onCallUsersState.data).map(key =>
      transformNormalizedToTyped<OnCallUser>(
        onCallUsersState.data[key],
        OnCallUserAttributeNames,
      ),
    )
  },
)
