import styled from '@emotion/styled'

import { colors } from 'packages/styles'

export const Content = styled.div`
  width: 100%;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const HeaderCell = styled.th`
  text-transform: uppercase;
  text-align: center;
  color: ${colors.midnight};
  font-weight: bold;
  border-bottom: 3px solid ${colors.midnight10};
  padding: 20px 10px;
  border-right: 1px solid ${colors.midnight10};

  &:last-child {
    border-right: none;
  }
`

export const BodyRow = styled.tr`
  border-bottom: 1px solid ${colors.midnight10};
  color: ${colors.dusk};

  &:hover {
    background-color: ${colors.midnight10};
  }
`

export const BodyCell = styled.td`
  padding: 20px 10px;
  border-right: 1px solid ${colors.midnight10};
  text-align: left;

  &:last-child {
    border-right: none;
    text-align: right;
  }
`

export const CellContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
`

export const EditIcon = styled.span`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: auto;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
