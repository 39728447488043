import { createSelector } from 'reselect'

import { ApplicationState } from 'app/hkhub/store/store'

export const getUserHkId = createSelector(
  (state: ApplicationState) => state,
  (state: ApplicationState): string | undefined => {
    const currentUserId = state.users.authUserId

    const hkId = Object.values(state.housekeepers?.data || {}).find(
      hk => hk.relationships?.user.data.id === currentUserId,
    )?.id

    return hkId
  },
)
