import React from 'react'
import { useSelector } from 'react-redux'
import Select, { SingleValue, StylesConfig } from 'react-select'

import { useI18n } from 'packages/i18n'
import { colors } from 'packages/styles'

import { Slugs } from 'app/hkhub/i18n'
import { useAppSelector } from 'app/hkhub/store/hooks'
import { ApplicationState } from 'app/hkhub/store/store'
import { Unit } from 'app/hkhub/store/units'
import { getUnits, getUnitsIsLoading } from 'app/hkhub/store/units/selectors'

import {
  getHighlightedUnitSearchResults,
  filterUnits,
} from './UnitSearchSelector.helpers'

export enum UnitSearchSelectorTestIds {
  container = 'UnitSearchSelector_container',
}

export type UnitSearchSelectorProps = {
  onUnitChange: (unit: SingleValue<Unit>) => void
}

const customStyles: StylesConfig<Unit> = {
  control: styles => ({
    ...styles,
    '&:hover': {
      borderColor: colors.midnight30,
    },
    borderColor: colors.midnight30,
    boxShadow: 'none',
  }),
  dropdownIndicator: styles => ({ ...styles, color: colors.midnight80 }),
  singleValue: styles => ({
    ...styles,
    color: colors.midnight80,
    fontWeight: 600,
  }),
}

export const UnitSearchSelector: React.FC<UnitSearchSelectorProps> = React.memo(
  ({ onUnitChange }) => {
    const { ut } = useI18n()
    const units = useSelector((state: ApplicationState) =>
      getUnits(state.units),
    )
    const isLoading = useAppSelector(getUnitsIsLoading)

    const noOptionsMessage = React.useCallback(
      ({ inputValue }) =>
        inputValue === ''
          ? ut(Slugs.typeToSearch)
          : ut(Slugs.unitSearchNoResults),
      [ut],
    )

    return (
      <div data-testid={UnitSearchSelectorTestIds.container}>
        <Select
          options={units}
          placeholder={ut(Slugs.searchUnitNameOrCode)}
          getOptionValue={unit => unit.id}
          onChange={onUnitChange}
          filterOption={filterUnits}
          formatOptionLabel={getHighlightedUnitSearchResults}
          noOptionsMessage={noOptionsMessage}
          isLoading={isLoading}
          styles={customStyles}
          isMulti={false}
        />
      </div>
    )
  },
)
