import get from 'lodash/get' // eslint-disable-line

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  UsersApiResponse,
  NormalizedUsersApiResponse,
  UsersServiceResponse,
} from './users.types'

const URL = '/users'

export const emptyUsersResponse: NormalizedUsersApiResponse = {
  normalized: { user: {} },
  raw: { data: [] },
}

export const usersService = {
  /**
   * Sends a GET request to the server to fetch the currently logged-in User.
   * @param params
   */
  async fetchCurrentUser(params: RequestOptions = {}): UsersServiceResponse {
    const response: UsersApiResponse = await api.get('/users/me', {
      params,
    })
    return get(response, 'data', emptyUsersResponse)
  },

  /**
   * Preforms a GET request for all users
   * @param params
   */
  async fetchUsers(
    params: RequestOptions = {},
    forceToken = '',
  ): UsersServiceResponse {
    const headers = forceToken ? { Authorization: `Bearer ${forceToken}` } : {}

    const response: UsersApiResponse = await api.get(URL, {
      params,
      headers,
    })
    return get(response, 'data', emptyUsersResponse)
  },

  /**
   * Fetches coverage partner data for a specific user
   * @param userId - The ID of the user to fetch coverage partner for
   * @param params - Request options
   */
  async fetchUserCoveragePartner(
    userId: string,
    params: RequestOptions = {},
  ): UsersServiceResponse {
    const response: UsersApiResponse = await api.get(`${URL}/${userId}`, {
      params,
    })
    return get(response, 'data', emptyUsersResponse)
  },

  /**
   * Creates a coverage partner relationship between two users
   * @param params - Request options containing the coverage partner relationship data
   */
  async createCoveragePartner(params: RequestOptions): UsersServiceResponse {
    const response: UsersApiResponse = await api.post('/coverage_partner', params)
    return get(response, 'data', emptyUsersResponse)
  },
}
