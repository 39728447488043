import { AxiosResponse } from 'axios'
import snakeCase from 'lodash/snakeCase'

import {
  JSONApiObjectWithRelationshipsMap,
  ToOneRelationship,
  JSONApiObjectWithRelationships,
} from 'packages/grimoire/src/utils'
import {
  JSONApiObjectMap,
  NormalizedJSONApiResponse,
} from 'packages/utils/store/jsonapi.types'

/**********************************************************
 * UTILITY TYPES
 *********************************************************/
// Values for 'bookingType' field on Reservations; note that these map directly to the values that the API can return
export enum ReservationBookingType {
  GUEST = 'guest_reservation',
  OWNER = 'owner_hold',
  UNKNOWN = 'unknown',
  VACASA = 'vacasa_hold',
}

export type UnitReservationsBuckets = {
  [unitId: string]: Reservation[]
}

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum ReservationsActionTypes {
  FETCH_NEXT_RESERVATIONS_BY_DATE = 'RESERVATIONS/API/FETCH_NEXT_RESERVATIONS_BY_DATE',
  FETCH_NEXT_RESERVATIONS_BY_DATE_FAILURE = 'RESERVATIONS/FETCH_NEXT_RESERVATIONS_BY_DATE_FAILURE',
  FETCH_NEXT_RESERVATIONS_BY_DATE_SUCCESS = 'RESERVATIONS/FETCH_NEXT_RESERVATIONS_BY_DATE_SUCCESS',

  FETCH_NEXT_RESERVATIONS_FOR_ZONE_AND_DATE_RANGE = 'RESERVATIONS/API/FETCH_NEXT_RESERVATIONS_FOR_ZONE_AND_DATE_RANGE',
  FETCH_NEXT_RESERVATIONS_FOR_ZONE_AND_DATE_RANGE_FAILURE = 'RESERVATIONS/FETCH_NEXT_RESERVATIONS_FOR_ZONE_AND_DATE_RANGE_FAILURE',
  FETCH_NEXT_RESERVATIONS_FOR_ZONE_AND_DATE_RANGE_SUCCESS = 'RESERVATIONS/FETCH_NEXT_RESERVATIONS_FOR_ZONE_AND_DATE_RANGE_SUCCESS',

  FETCH_NEXT_RESERVATION_BY_CLEAN = 'RESERVATIONS/API/FETCH_NEXT_RESERVATION_BY_CLEAN',
  FETCH_NEXT_RESERVATION_BY_CLEAN_FAILURE = 'RESERVATIONS/FETCH_NEXT_RESERVATION_BY_CLEAN_FAILURE',
  FETCH_NEXT_RESERVATION_BY_CLEAN_SUCCESS = 'RESERVATIONS/FETCH_NEXT_RESERVATION_BY_CLEAN_SUCCESS',

  FETCH_RESERVATION_BY_ID = 'RESERVATIONS/API/FETCH_RESERVATION_BY_ID',
  FETCH_RESERVATION_BY_ID_FAILURE = 'RESERVATIONS/FETCH_RESERVATION_BY_ID_FAILURE',
  FETCH_RESERVATION_BY_ID_SUCCESS = 'RESERVATIONS/FETCH_RESERVATION_BY_ID_SUCCESS',
}

export type ReservationsState = {
  byDateUnitHash: JSONApiObjectMap<ReservationAttributes>
  data: JSONApiReservationMap
  error?: Error
  isLoading: boolean
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
type ReservationResponse = {
  reservation: JSONApiReservationMap
}

export type NormalizedReservationsApiResponse =
  NormalizedJSONApiResponse<ReservationResponse>
export type ReservationsApiResponse =
  AxiosResponse<NormalizedReservationsApiResponse>
export type ReservationsServiceResponse =
  Promise<NormalizedReservationsApiResponse>

/**********************************************************
 * RESERVATION
 *********************************************************/
export type Reservation = {
  id: string
} & ReservationAttributes

export type ReservationAttributes = {
  bookingType: ReservationBookingType
  checkIn: string
  checkOut: string
  extraCleaningDays: number
}

export const ReservationAttributeNames: string[] = [
  'bookingType',
  'checkIn',
  'checkOut',
  'extraCleaningDays',
]

export const ReservationApiFields =
  ReservationAttributeNames.map<string>(snakeCase)

export type ReservationRelationships = {
  unit: ToOneRelationship
}

export type RawReservation = JSONApiObjectWithRelationships<
  ReservationAttributes,
  ReservationRelationships
>

export type JSONApiReservationMap = JSONApiObjectWithRelationshipsMap<
  ReservationAttributes,
  ReservationRelationships
>

export type JSONApiReservationMapFromTasks = JSONApiObjectWithRelationshipsMap<
  ReservationAttributes,
  ReservationRelationships
>

/**********************************************************
 * Minimum required fields to check whether a unit
 * has a reservation on a selected date
 *********************************************************/

export type UnitReservationAttributes = {
  checkIn: string
  checkOut: string
}

type JSONApiUnitReservationMap = JSONApiObjectWithRelationshipsMap<
  UnitReservationAttributes,
  unknown
>

type UnitReservationResponse = {
  reservation: JSONApiUnitReservationMap
}

type NormalizedUnitReservationApiResponse =
  NormalizedJSONApiResponse<UnitReservationResponse>

export type UnitReservationApiResponse =
  AxiosResponse<NormalizedUnitReservationApiResponse>

export type UnitReservationServiceResponse =
  Promise<NormalizedUnitReservationApiResponse>
