import { produce } from 'immer'
import get from 'lodash/get' // eslint-disable-line
import { ActionType, getType } from 'typesafe-actions'

import { clearZoneAction } from 'app/hkhub/store/zones/actions'

import { fetchCleanAction } from '../cleans/actions'
import { addHksToZoneAction, removeHksFromZoneAction } from '../zones/actions'
import {
  addBorrowedZoneToHkAction,
  clearHkSearchResultsAction,
  fetchHousekeepersByManagerAction,
  fetchHousekeepersByZoneAction,
  removeBorrowedZoneFromHkAction,
  searchHousekeepersAction,
  updateHkInSearchResultsAction,
} from './actions'
import { HousekeepersState } from './housekeepers.types'
import {
  emptyNormalizedHousekeeperData,
  emptyNormalizedHkSearchResults,
} from './housekeepers.utils'

const initialState: HousekeepersState = {
  data: {},
  error: undefined,
  housekeeperTier: {},
  isLoading: false,
  searchResults: {
    housekeeper: {},
    user: {},
    zone: {},
  },
  user: {},
}

const actions = {
  addBorrowedZoneToHkAction,
  addHksToZoneAction,
  clearHkSearchResultsAction,
  clearZoneAction,
  fetchCleanAction,
  fetchHousekeepersByManagerAction,
  fetchHousekeepersByZoneAction,
  removeBorrowedZoneFromHkAction,
  removeHksFromZoneAction,
  searchHousekeepersAction,
  updateHkInSearchResultsAction,
}

const fallbackError = new Error('Unknown Error in housekeepersReducer')

type HousekeepersActionsTypes = ActionType<typeof actions>

// helper function to update the state/draft by removing the HK at 'hkId' along with its associated records
const removeHkFromCurrentState = (
  state: HousekeepersState,
  draft: HousekeepersState,
  hkId: string,
) => {
  const hk = state.data[hkId]
  if (hk) {
    // remove the associated User record from the local store
    const userId = get(hk, ['relationships', 'user', 'data', 'id'])
    if (userId && draft.user[userId]) {
      delete draft.user[userId]
    }

    // remove the associated manager User record if it exists
    const managerId = get(hk, ['relationships', 'manager', 'data', 'id'])
    if (managerId && draft.user[managerId]) {
      delete draft.user[managerId]
    }

    delete draft.data[hkId]
  }
}

export const housekeepersReducer = (
  state = initialState,
  action: HousekeepersActionsTypes,
): HousekeepersState =>
  produce(state, (draft: HousekeepersState) => {
    switch (action.type) {
      // FETCH HOUSEKEEPERS
      case getType(fetchHousekeepersByManagerAction.request):
      case getType(fetchHousekeepersByZoneAction.request):
        draft.isLoading = true
        return

      case getType(fetchHousekeepersByManagerAction.success):
      case getType(fetchHousekeepersByZoneAction.success): {
        draft.error = undefined
        draft.isLoading = false

        const normalized = get(
          action,
          'payload.normalized',
          emptyNormalizedHousekeeperData,
        )
        draft.data = normalized.housekeeper || {}
        draft.housekeeperTier = normalized.housekeeperTier || {}
        
        // Preserve existing user data while adding new data
        draft.user = {
          ...state.user,
          ...(normalized.user || {}),
          ...(normalized.manager || {}),
        }

        return
      }

      case getType(fetchCleanAction.success): {
        const housekeepers = action.payload.normalized?.housekeeper || {}
        const users = action.payload.normalized?.user || {}
        const managers = action.payload.normalized?.manager || {}

        // IMPORTANT: We do NOT want to overwrite the existing housekeepers in the store. We are not fetching zones on this request, reversing this order will break clean hydration
        Object.entries(housekeepers).forEach(([hkId, hk]) => {
          if (!state.data[hkId]) draft.data[hkId] = hk
        })

        Object.entries(users).forEach(([userId, user]) => {
          if (!state.user[userId]) draft.user[userId] = user
        })

        // Add manager data to user store if it exists
        Object.entries(managers).forEach(([managerId, manager]) => {
          if (!state.user[managerId]) draft.user[managerId] = manager
        })

        return
      }

      // FAILURE STATES
      case getType(addBorrowedZoneToHkAction.failure):
      case getType(fetchHousekeepersByManagerAction.failure):
      case getType(fetchHousekeepersByZoneAction.failure):
      case getType(searchHousekeepersAction.failure):
      case getType(updateHkInSearchResultsAction.failure): {
        draft.isLoading = false
        draft.error = get(action, 'payload', fallbackError)
        return
      }

      // ADD/REMOVE HOUSEKEEPERS
      case getType(removeHksFromZoneAction.success): {
        const removedHkIds = get(
          action,
          'payload.normalized.relationshipUpdate.relationshipIds',
          [],
        )

        removedHkIds.forEach(hkId => {
          removeHkFromCurrentState(state, draft, hkId)
        })

        return
      }

      // BORROW HOUSEKEEPERS
      case getType(removeBorrowedZoneFromHkAction.success): {
        const removedBorrowedHkId = get(
          action,
          'payload.normalized.relationshipUpdate.ownerId',
        )
        if (removedBorrowedHkId) {
          removeHkFromCurrentState(state, draft, removedBorrowedHkId)
        }

        return
      }

      // SEARCH HOUSEKEEPERS
      case getType(clearHkSearchResultsAction):
        draft.searchResults = {
          housekeeper: {},
          user: {},
          zone: {},
        }

        return

      case getType(searchHousekeepersAction.success): {
        draft.error = undefined
        draft.isLoading = false

        const normalized = get(
          action,
          'payload.normalized',
          emptyNormalizedHkSearchResults,
        )
        draft.searchResults = {
          housekeeper: normalized.housekeeper || {},
          // eslint-disable-next-line
          user: (normalized.user as any) || {},
          // eslint-disable-next-line
          zone: (normalized.zone as any) || {},
        }

        return
      }

      case getType(updateHkInSearchResultsAction.success): {
        const normalized = get(
          action,
          'payload.normalized',
          emptyNormalizedHkSearchResults,
        )
        const hkMap = normalized.housekeeper
        if (hkMap) {
          const hk: any = Object.values(hkMap)[0] // eslint-disable-line
          draft.searchResults.housekeeper[hk?.id] = hk
        }

        return
      }

      // clear all data when switching the current zone
      case getType(actions.clearZoneAction): {
        draft.data = {}
        return
      }
    }
  })
