import { AxiosError } from 'axios'

type BackendError = {
  code: string
  detail: string
  status: string
  title: string
}

export function isExistingVisitError(
  error: string | Error | AxiosError | undefined,
): boolean {
  if (error instanceof AxiosError) {
    return Boolean(
      error.response?.data?.errors.some(
        (error: BackendError) => error.code === '02010A',
      ),
    )
  }

  return false
}
