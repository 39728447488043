import { debounce } from 'lodash/fp'
import React from 'react'
import { useClickAway, useUpdateEffect } from 'react-use'

import { IconName, SvgIcon } from 'packages/iconic'
import { useBreakpoint } from 'packages/styles'
import {
  addDays,
  createDateObject,
  DateFormat,
  formatLocalized,
} from 'packages/utils/dateHelpers'
import { Events, track } from 'packages/wiretap'

import { useUnitFiltersContext } from 'app/hkhub/components/schedule/components/units/contexts'
import { makeUnitSearchFilter } from 'app/hkhub/components/schedule/components/units/utils'
import { useScheduleMatchParams } from 'app/hkhub/components/schedule/hooks'
import { useZoneContext } from 'app/hkhub/components/zone/ZonePage/ZonePage.context'
import { Slugs, useI18n } from 'app/hkhub/i18n'

import { CreateVisitModal } from '../CreateVisit/CreateVisitModal'
import { ScheduleViewRangeMenuContainer as ScheduleViewRangeMenu } from '../ScheduleViewRangeMenu'
import * as St from './ScheduleControls.styles'

const UNIT_SEARCH_DEBOUNCE_TIME = 350

type DateIncrement = -1 | 0 | 1

export enum ScheduleControlsTestIds {
  unitSearch = 'ScheduleControls__unitSearch',
  unitSearchContainer = 'ScheduleControls__unitSearchContainer',
}

export type ScheduleControlsProps = {
  onChangeDate: (nextDate: Date) => void
}

export const ScheduleControls: React.FC<ScheduleControlsProps> = ({
  onChangeDate,
}) => {
  const { t } = useI18n()

  const { date, dayCount, entity } = useScheduleMatchParams()
  const breakpoint = useBreakpoint()

  const { zone } = useZoneContext()
  const {
    clearAllFilters,
    disableNeedToClearSearchField,
    needToClearSearchField,
    resetToDefaultState,
    setSearchFilters,
  } = useUnitFiltersContext()

  const isStaffView = entity === 'staff'
  const isUnitView = entity === 'unit'
  const isSmallScreen = breakpoint === 'SM'
  const isLargeScreen = breakpoint === 'LG'

  const changeDate = (increment: DateIncrement, baseDate: Date) => {
    const nudgeAmt = dayCount === 7 ? dayCount : 1
    const newDate = addDays(baseDate, nudgeAmt * increment)

    onChangeDate(newDate)
    clearAllFilters()
  }

  const gotoNext = () => {
    changeDate(1, date)
  }

  const gotoPrev = () => {
    changeDate(-1, date)
  }

  const gotoToday = () => {
    changeDate(0, createDateObject())
  }

  const debouncedSetSearchFilters = React.useMemo(
    () =>
      debounce(UNIT_SEARCH_DEBOUNCE_TIME, (searchString: string) => {
        if (searchString) {
          setSearchFilters([makeUnitSearchFilter(searchString)])

          /* eslint-disable @typescript-eslint/naming-convention */
          track(Events.hubUnitSearch, {
            search_string: searchString,
            zone_id: zone.id,
          })
          /* eslint-enable @typescript-eslint/naming-convention */
        } else {
          setSearchFilters([])
        }
      }),
    [setSearchFilters, zone.id],
  )

  const handleSearchChange = React.useCallback(
    (searchString: string) => {
      if (needToClearSearchField) {
        // if we're here, the search filter has been cleared externally, resulting in force-clearing
        // the input field, so we just need to un-set the flag but NOT perform a new search
        disableNeedToClearSearchField()
      } else {
        debouncedSetSearchFilters(searchString)
      }
    },
    [
      debouncedSetSearchFilters,
      disableNeedToClearSearchField,
      needToClearSearchField,
    ],
  )

  const [isSearchOpen, setIsSearchOpen] = React.useState(false)
  const unitSearchFieldRef = React.useRef(null)

  /**
   * Hide the input field (on small screens) on outside click.
   * The extra logic here ensures that clicking the "clear" button in the input field DOES NOT
   * trigger the hide, since the clear button is technically an "outside" click
   * as far as the <input> field is concerned (the input field and the icon are siblings
   * under the same wrapper component).
   */
  useClickAway(unitSearchFieldRef, event => {
    const inputParent = document.querySelector(
      `#${ScheduleControlsTestIds.unitSearchContainer}`,
    )

    if (inputParent?.contains(event?.target as Node)) return

    setIsSearchOpen(false)
  })

  // after any navigation has occurred, clear filters as necessary
  // (doing this after nav ensures that we don't trigger expensive unnecessary
  // re-renders of virtualized lists from the previous view)
  useUpdateEffect(() => {
    if (isStaffView) {
      resetToDefaultState()
      setIsSearchOpen(false)
    }
  }, [isStaffView, resetToDefaultState])

  return (
    <St.ScheduleControlsContainer>
      <St.ScheduleControlsContent breakpoint={breakpoint}>
        <St.ControlGroup>
          {isLargeScreen && (
            <St.TodayButton onClick={gotoToday} buttonType={'utilityInverted'}>
              {t(Slugs.today)}
            </St.TodayButton>
          )}

          <St.DateDisplay>
            {formatLocalized(date, DateFormat.MonthNameAbrevWithYear)}
          </St.DateDisplay>

          <St.ArrowGroup>
            <St.ArrowButton
              dataTestId={'back-arrow'}
              onClick={gotoPrev}
              buttonType={'utilityInverted'}
            >
              <SvgIcon size={28} icon={IconName.chevronLeft} />
            </St.ArrowButton>

            <St.ArrowButton
              dataTestId={'forward-arrow'}
              onClick={gotoNext}
              buttonType={'utilityInverted'}
            >
              <SvgIcon size={28} icon={IconName.chevronRight} />
            </St.ArrowButton>
          </St.ArrowGroup>
        </St.ControlGroup>

        <St.UnitFilterControls>
          {isUnitView && (
            <St.UnitSearchField
              breakpoint={breakpoint}
              clearable={true}
              containerId={ScheduleControlsTestIds.unitSearchContainer}
              forceClear={needToClearSearchField}
              hidden={isSmallScreen && !isSearchOpen}
              id={ScheduleControlsTestIds.unitSearch}
              leftIcon={IconName.search}
              onInputChange={handleSearchChange}
              placeholder={t(Slugs.searchUnits)}
              ref={unitSearchFieldRef}
            />
          )}
        </St.UnitFilterControls>

        <St.ViewControls>
          {isSmallScreen && (
            <>
              {isUnitView && (
                <SvgIcon
                  icon={IconName.search}
                  onClick={() => setIsSearchOpen(true)}
                  size={24}
                />
              )}
              <SvgIcon onClick={gotoToday} size={22} icon={IconName.calendar} />
            </>
          )}

          {isLargeScreen && <ScheduleViewRangeMenu />}
        </St.ViewControls>
        <CreateVisitModal />
      </St.ScheduleControlsContent>
    </St.ScheduleControlsContainer>
  )
}
