import { createSelector } from 'reselect'

import { ApplicationState } from 'app/hkhub/store/store'
import { Visit } from 'app/hkhub/store/visits'
import { hydrateRawVisit } from 'app/hkhub/store/visits/utils'

export const getVisitsByUnitId = createSelector(
  (state: ApplicationState) => state.visits,
  (_, unitId: string) => unitId,
  (visitsState: ApplicationState['visits'], unitId: string): Visit[] => {
    if (!unitId) {
      return []
    }

    const rawVisits = visitsState.data

    return Object.values(rawVisits).reduce((acc, rawVisit) => {
      if (rawVisit.relationships?.unit.data.id === unitId) {
        acc.push(hydrateRawVisit(rawVisit))
      }

      return acc
    }, [] as Visit[])
  },
)
