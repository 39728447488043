import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'app/hkhub/store/store'

import { CoveragePartnerDrawerState } from '../ui.types'

// Input selector - gets the raw drawer state
const getCoveragePartnerDrawerStateRaw = (state: RootState) => 
  state.ui.drawers.coveragePartnerDrawer

// Memoized selector - transforms the raw state into a more specific form
export const getCoveragePartnerDrawerState = createSelector(
  [getCoveragePartnerDrawerStateRaw],
  (drawerState): CoveragePartnerDrawerState => ({
    isOpen: drawerState.isOpen,
    userId: drawerState.userId,
    userName: drawerState.userName,
  }),
) 