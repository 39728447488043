import { createSelector } from 'reselect'

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import { ApplicationState } from '../../store'
import {
  OnCallUser,
  OnCallUserAttributeNames,
  OnCallUserState,
} from '../onCallUser.types'

export const getOnCallUserForUnit = createSelector(
  (state: ApplicationState) => state.onCallUsers,
  (_: unknown, unitId: string) => unitId,
  (
    onCallUsersState: OnCallUserState,
    unitId: string,
  ): OnCallUser | undefined => {
    const onCallUser = Object.keys(onCallUsersState.data).find(key => {
      return Object.keys(
        onCallUsersState.data[key].attributes.oncallAt,
      ).includes(unitId.toString())
    })

    if (!onCallUser) return undefined

    const parsedOnCallUser = transformNormalizedToTyped<OnCallUser>(
      onCallUsersState.data[onCallUser],
      OnCallUserAttributeNames,
    )

    return parsedOnCallUser
  },
)
