import { createAsyncAction } from 'typesafe-actions'

import {
  RequestOptions,
  RequestConfig,
} from 'packages/utils/store/jsonapi.types'

import { housekeepersService } from '../housekeepers.service'
import {
  HousekeepersActionTypes,
  NormalizedHousekeepersApiResponse,
} from '../housekeepers.types'

export const fetchHousekeepersByManagerAction = createAsyncAction(
  HousekeepersActionTypes.FETCH_HOUSEKEEPERS_BY_MANAGER,
  HousekeepersActionTypes.FETCH_HOUSEKEEPERS_BY_MANAGER_SUCCESS,
  HousekeepersActionTypes.FETCH_HOUSEKEEPERS_BY_MANAGER_FAILURE,
)<
  RequestConfig<NormalizedHousekeepersApiResponse>,
  NormalizedHousekeepersApiResponse,
  Error
>()

export const getParams = (managerId: string): RequestOptions => ({
  filter: {
    is_active: true,
    manager: managerId,
  },
  include: ['user', 'zone', 'tier', 'manager', 'borrowed_zones'],
  page: { size: 500 },
})

export const fetchHousekeepersByManager = (managerId: string) => async dispatch => {
  try {
    const params = getParams(managerId)
    const request = housekeepersService.fetchHousekeepers.bind(null, params)
    const response = await dispatch(
      fetchHousekeepersByManagerAction.request({ request }),
    )
    dispatch(fetchHousekeepersByManagerAction.success(response))
    return response.normalized
  } catch (error) {
    dispatch(fetchHousekeepersByManagerAction.failure(error))
    throw error
  }
} 