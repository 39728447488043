import { createAction } from 'typesafe-actions'

import { UiActionTypes } from '../ui.types'

export type SetCoveragePartnerDrawerStatePayload = {
  isOpen: boolean
  userId: string
  userName?: string
}

export const setCoveragePartnerDrawerStateAction = createAction(
  UiActionTypes.SET_COVERAGE_PARTNER_DRAWER_STATE,
)<SetCoveragePartnerDrawerStatePayload>() 