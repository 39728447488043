import React from 'react'

import { Button, Loader, Select } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { AsyncState } from 'packages/utils/hooks'

import { St as CleanAssignmentEditorSt } from 'app/hkhub/components/schedule/components/common/CleanDrawer/CleanAssignments/AssignmentEditor/AssignmentEditor.styles'
import { parseHkName } from 'app/hkhub/components/schedule/helpers/housekeeperHelpers'
import { Slugs } from 'app/hkhub/i18n'
import { Housekeeper } from 'app/hkhub/store/housekeepers'

export enum VisitAssignmentEditorTestIds {
  cancelButton = 'VisitAssignmentEditor__cancelButton',
  saveButton = 'VisitAssignmentEditor__saveButton',
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    assignment: t(Slugs.assignment),
    assignmentChangeError: t(Slugs.assignmentChangeError),
    cancel: t(Slugs.cancel),
    save: t(Slugs.save),
    selectHousekeeper: t(Slugs.selectHousekeeper),
    visit: t(Slugs.visit),
  }
}

export type VisitAssignmentEditorProps = {
  disableSubmit: boolean
  housekeepers: Housekeeper[]
  onCancelEditClick: () => void
  resetUpdateState: () => void
  selectedHk: Housekeeper | undefined
  setSelectedHkId: (id: string) => void
  updateState: AsyncState<unknown>
  updateVisitFn: () => void
}

export const VisitAssignmentEditor: React.FC<VisitAssignmentEditorProps> =
  React.memo(
    ({
      disableSubmit,
      selectedHk,
      housekeepers,
      onCancelEditClick,
      resetUpdateState,
      setSelectedHkId,
      updateState,
      updateVisitFn,
    }) => {
      const strings = useTranslations()
      return (
        <CleanAssignmentEditorSt.AssignmentEditorContainer>
          <CleanAssignmentEditorSt.EditableSectionTitle>
            {strings.assignment}
          </CleanAssignmentEditorSt.EditableSectionTitle>
          {updateState.loading && <Loader />}
          <CleanAssignmentEditorSt.EditableSection>
            <CleanAssignmentEditorSt.HKAssignmentWrapper>
              <Select
                fullWidth={true}
                getOptionLabel={parseHkName}
                onChange={hk => setSelectedHkId(hk.id)}
                options={housekeepers}
                placeholder={strings.selectHousekeeper}
                selectedValue={selectedHk}
              />
            </CleanAssignmentEditorSt.HKAssignmentWrapper>
          </CleanAssignmentEditorSt.EditableSection>{' '}
          <div>
            {updateState.error && (
              <CleanAssignmentEditorSt.Alert
                alertType={'danger'}
                onClose={resetUpdateState}
              >
                <span>{strings.assignmentChangeError}</span>
              </CleanAssignmentEditorSt.Alert>
            )}

            <CleanAssignmentEditorSt.Buttons>
              <Button
                dataTestId={VisitAssignmentEditorTestIds.cancelButton}
                onClick={onCancelEditClick}
                buttonType={'utility'}
              >
                {strings.cancel}
              </Button>

              <Button
                dataTestId={VisitAssignmentEditorTestIds.saveButton}
                disabled={disableSubmit}
                onClick={updateVisitFn}
                buttonType={'primary'}
              >
                {strings.save} {strings.visit}
              </Button>
            </CleanAssignmentEditorSt.Buttons>
          </div>
        </CleanAssignmentEditorSt.AssignmentEditorContainer>
      )
    },
  )
